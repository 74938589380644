.profileContainer {
  height: calc(100vh - 122px);
}

.profileContainer .innerContainer {
  max-width: 1470px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 15px 50px;
}

.profileContainer .innerContainer .formWrapper {
  background: #ffffff;
  border-radius: 16px;
  max-width: 720px;
  width: 100%;
  margin: auto;
  padding: 45px 40px;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  position: relative;
  z-index: 9;
  box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 24px;
}

.formWrapper .authTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #191c21;
  margin: 0px 0px 24px;
  font-family: 'Rubik', sans-serif;
  text-align: left;
  width: 100%;
}

.formWrapper .fieldWrapper {
  text-align: left;
  margin-bottom: 24px;
  width: calc(50% - 12px);
}

.fieldWrapper label {
  color: #191c21;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 16px;
}

.formWrapper .errorText {
  margin-bottom: 0px;
  position: absolute;
  font-size: 12px;
  line-height: 1.4;
  color: #f5004a;
  margin-top: 5px;
}

.fieldWrapper input {
  width: 100%;
  border: 1px solid #d1d2d3;
  border-radius: 8px;
  height: 48px;
  padding: 10px 16px 10px 14px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  font-family: 'Rubik', sans-serif;
  background: #f4f6f9;
}

.formWrapper .fieldWrapper input,
.formWrapper .fieldWrapper select {
  border: 1px solid #d1d2d3;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  padding: 10px 10px 10px 16px;
  background: #f4f6f9;
}

.formWrapper .fieldWrapper input:disabled {
  cursor: not-allowed;
  background: #dfdcdc;
  opacity: 0.5;
}

.dropDownOption {
  position: relative;
}

.dropDownOption .dropDownIcon {
  position: absolute;
  right: 25px;
  top: 63px;
}

.fieldWrapper.phoneNum>div>input {
  width: calc(100% - 30px) !important;
}

.formWrapper .buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
}

.formWrapper .buttonContainer .submitWrapper button {
  background: #51ab86;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  line-height: 28px;
  max-width: 375px;
  padding: 10px 15px;
  text-align: center;
  width: 90px;
  min-width: 90px;
  position: relative;
}

.formWrapper .buttonContainer .submitWrapper button .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.formWrapper .buttonContainer .submitWrapper button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.formWrapper .buttonContainer .cancelWrapper button {
  border: 1px solid #51ab86;
  border-radius: 8px;
  color: #51ab86;
  background-color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  line-height: 28px;
  max-width: 375px;
  padding: 10px 15px;
  text-align: center;
  width: auto;
}

.imageUploaderContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageUploaderContainer .imageInnerContainer {
  position: relative;
  height: 120px;
  width: 120px;
  margin-bottom: 32px;
}

.imageUploaderContainer .imageInnerContainer .camIconContainer {
  position: absolute;
  bottom: -12px;
  right: -12px;
  height: 30px;
  width: 30px;
  background-color: #51ab86;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imageUploaderContainer .imageInnerContainer input {
  display: none;
}

.imageUploaderContainer .imageInnerContainer .userAvatar {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  border: 2px solid #d1d2d3;
  object-fit: cover;
  background-position: center center;
}

.imageUploaderContainer .camIconContainer img {
  filter: brightness(0) invert(1);
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 5px 10px 32px 0px #277FE512;
  width: 688px;
}

.modalBodyContainer .modalHeader {
  padding: 24px 24px 16px;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.modalHeader .closeIcon {
  filter: brightness(1) invert(1);
  cursor: pointer;
}

.modalBodyContainer .modalBodyInnerContainer {
  /* padding: 0 24px; */
  width: calc(100% - 48px);
  margin: 0 auto;
  max-height: 480px;
  height: 100%;
  position: relative;
}

.modalBodyContainer .camLayer {
  position: absolute;
  top: 0px;
  bottom: 0;
  height: 481px;
  width: 640px;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0.7;
}

.camLayer img {
  object-fit: cover;
  height: 481px;
}

.modalBodyContainer .webCamContainer {
  width: 640px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
}

.modalBodyContainer .capturedImage {
  width: 640px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
}

.modalBodyContainer .capturedImage img {
  height: 100%;
  width: 100%;
}

.modalBodyContainer .modalFooter {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.modalBodyContainer .modalFooter .captureBtn {
  background-color: #51ab86;
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.modalBodyContainer .modalFooter .submitBtn {
  background-color: #51ab86;
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  width: calc(50% - 8px);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.modalBodyContainer .modalFooter .retakeBtn {
  background-color: #fff;
  border: 1px solid #51ab86;
  color: #51ab86;
  padding: 12px;
  border-radius: 8px;
  width: calc(50% - 8px);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width:767px) {
  .profileContainer {
    height: calc(100vh - 73px);
  }

  .profileContainer .innerContainer {
    padding: 0;
    height: 100%;
    overflow-y: auto;
  }

  .profileContainer .innerContainer .formWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .modalBodyContainer {
    width: calc(100vw - 32px);
  }

  .modalBodyContainer .modalBodyInnerContainer {
    height: auto;
    max-height: none;
    max-width: calc(100% - 32px);
  }

  .modalBodyContainer .modalBodyInnerContainer .camLayer {
    width: 100%;
    left: 0;
    right: 0;
    height: calc(100% + 1px);
  }

  .modalBodyContainer .modalBodyInnerContainer .camLayer img {
    height: 100%;
  }

  .modalBodyContainer .modalBodyInnerContainer .capturedImage,
  .modalBodyContainer .modalBodyInnerContainer .webCamContainer {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width:575px) {

  .profileContainer .innerContainer {
    padding-bottom: 40px;
  }

  .profileContainer .innerContainer .formWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .profileContainer .innerContainer .formWrapper .fieldWrapper {
    width: 100%;
    max-width: 100%;
  }

  .modalBodyContainer .modalHeader h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  .modalBodyContainer .modalFooter {
    flex-direction: column-reverse;
  }

  .modalBodyContainer .modalFooter .retakeBtn,
  .modalBodyContainer .modalFooter .submitBtn {
    width: 100%;
  }
}