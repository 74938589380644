.layoutContainer {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.layoutBg {
    /* background: url(../../images/outer-bg.png) no-repeat bottom center; */
    position: relative;
    background-size: contain;
    background-color: #fff;
}
.sidebarOpen > div {
    -webkit-transform: translate(0vw, 0px);
    -ms-transform: translate(0vw, 0px);
    transform: translate(0vw, 0px);
    -webkit-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
}
.sidebarClose > div {
    -webkit-transform: translate(-100vw, 0px);
    -ms-transform: translate(-100vw, 0px);
    transform: translate(-100vw, 0px);
    -webkit-transition: all ease-in-out 0.5s;
    -o-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
}

.screenContainer {
    height: 100vh;
    width: 100vw;
}

.boxContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.95);
    background: #ffffff;
    border-radius: 16px;
    max-width: 540px;
    width: 100%;
    padding: 40px 32px;
    position: relative;
    z-index: 9;
    box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07);
}

.boxContainer a {
    font-size: 18px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #51ab86;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
}

.container {
    height: calc(100vh - 122px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.container .notFoundImage {
    width: 100%;
    max-width: 120px;
}
.container p {
    font-size: 1.4vw;
    line-height: 1.2;
    margin-top: 16px;
}

.btnGreen {
    width: 100%;
    max-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #fff;
    background-color: #51ab86;
    border-radius: 8px;
    min-height: 40px;
    margin-top: 16px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .container .textLg {
        font-size: 15vw;
    }
    .container p {
        font-size: 4vw;
    }
}

@-webkit-keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -512px -512px;
    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -512px -512px;
    }
}
