.lightboxImg {
    height: 90vh;
    width: 100vw;
    max-width: 100%;
    object-fit: cover;
}

.lightbox {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lightboxWrapper {
    width: 100%;
    max-width: 80%;
    height: 90vh;
    margin: auto;
    position: relative;
}
.lightboxWrapper .btn {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #e592368a;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
.lightboxWrapper .btn:hover {
    background-color: #e59236;
    transition: all ease-in-out 0.3s;
}
.lightboxWrapper .btn.btnRight {
    right: 20px;
}
.lightboxWrapper .btn.btnLeft {
    left: 20px;
}
.lightboxWrapper .btn.btnClose {
    top: 20px;
    right: 0px;
    border-radius: 0px;
}

@media screen and (max-width: 1024px) {
    .lightboxWrapper {
        max-width: 90%;
    }
}
@media screen and (max-width: 567px) {
    .lightboxImg {
        object-fit: contain;
    }
}
