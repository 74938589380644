/* .accordion__section {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
} */

.questionnarieContent {
    padding: 24px 16px;
}

.accordion {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    /* padding: 24px; */
    width: 100%;
    transition: 0.3s;
}

.questionnarieContent button .accordion__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Rubik';
    color: #3f4246;
}

.questionarrieWrapper .accordion.active {
    padding-bottom: 22px;
}

.accordionContent {
    background-color: white;
    overflow: auto;
    transition: max-height 0.6s ease;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
}

.accordionContent > p {
    padding-bottom: 12px;
}

.questionarrieWrapper {
    max-width: 64%;
    margin: auto;
    margin: 0px auto 56px;
}
.questionnarieContent {
    background: #ffffff;
    box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07);
    border-radius: 16px;
    margin-bottom: 24px;
    /* transition: .3s;    */
}
.iconAccordion {
    width: 24px;
    height: 24px;
    min-width: 30px;
    display: block;
    padding-left: 10px;
}
.content,
.accordionContent {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}
.content::-webkit-scrollbar,
.accordionContent::-webkit-scrollbar {
    display: none;
}
.questionnarieContent .content {
    padding-bottom: 24px;
}
@media screen and (max-width: 992px) {
    .questionarrieWrapper {
        max-width: 80%;
    }
}
@media screen and (max-width: 767px) {
    .questionarrieWrapper {
        max-width: 100%;
    }
}
