.container {
    width: 100%;
}

.tourPortalContainer {
    background-color: #f4f6f9;
}

.tourPortalContainer .innerContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    width: 1440px;
    max-width: 100%;
    margin: 0 auto;
    padding: 60px 15px;
    text-align: center;
}
.inlineItems .itemWrapper {
    display: flex;
    flex-wrap: wrap;
}

.inlineItems .itemWrapper p {
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #b2b3b5;
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
    .tourPortalContainer .innerContainer {
        max-width: 90%;
    }
}

.tourPortalContainer .innerContainer .locationDetailContainer {
    width: auto;
}

.innerContainer .locationDetailContainer h2 {
    font-size: 48px;
    line-height: 44px;
    font-family: 'Rubik';
    font-weight: 700;
    color: #191c21;
}

.innerContainer .locationDetailContainer h3 {
    margin-top: 24px;
    font-size: 32px;
    line-height: 44px;
    font-family: 'Rubik';
    font-weight: 700;
    color: #3674ee;
}

.innerContainer .locationDetailContainer .propertyName {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #b2b3b5;
    margin-top: 4px;
}

.facilities {
    margin: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facility {
    font-size: 18px;
    line-height: 26px;
    margin-right: 12px;
    margin-left: 12px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.facility img {
    display: inline-block;
    margin-right: 8px;
    width: 24px;
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
}

.inlineItems {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
}

.facilities button {
    color: #3674ee;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    background-color: transparent;
}

.inlineItems .selfGuide {
    display: flex;
    align-items: center;
    background-color: #3674ee;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    gap: 10px;
    cursor: pointer;
    justify-content: center;
}

.selfGuide img {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}

.chooseLocation {
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    padding: 40px 32px;
    border-radius: 17px;
    margin: 30px auto 80px;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.chooseLocation h2 {
    text-align: center;
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.chooseLocation .selectWrapper {
    margin-top: 32px;
}

.chooseLocation .btnGreen {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #fff;
    background-color: #51ab86;
    border-radius: 8px;
    min-height: 40px;
    margin-top: 32px;
    cursor: pointer;
}

.container .lowerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1440px;
    max-width: 100%;
    padding: 80px 0;
    margin: 0 auto;
}

.lowerContainer .linkRow {
    display: flex;
}

.linkRow .linkCol {
    flex-basis: 33.33%;
    text-align: center;
    padding: 0 16px;
}

.linkRow .linkCol div {
    text-align: center;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    font-family: 'Rubik';
    color: #51ab86;
    white-space: pre-wrap;
    text-decoration: underline;
    cursor: pointer;
}

.faqContainer {
    padding: 16px;
}

.faqContainer .faqHeader {
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    font-family: 'Rubik';
    padding: 0 15px;
    text-transform: capitalize;
    text-align: center;
}

@media screen and (max-width: 1600px) {
    .tourPortalContainer .innerContainer {
        justify-content: space-around;
    }
}

@media screen and (max-width: 1440px) {
    .linkRow .linkCol:first-child div {
        /* white-space: normal; */
    }
}

.linkRow .linkCol:first-child,
.linkRow .linkCol:nth-child(2) {
    border-right: 1px solid #d9d9d9;
}

.infoProperty {
    margin-top: 32px;
    font-size: 18px;
    font-weight: bold;
}

.linkWrapperFaq {
    font-size: 18px;
    font-weight: 600;
    color: #2c5fc4;
    text-align: left;
    margin-bottom: 30px;
    text-decoration: underline;
    cursor: pointer;
    /* margin-top: 25px; */
}
.linkWrapperFaq {
    margin-top: 30px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.link {
    margin-top: 20px;
}

@media screen and (max-width: 1280px) {
    .innerContainer .locationDetailContainer h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .inlineItems {
        flex-direction: column;
        gap: 16px;
    }

    .linkRow .linkCol div {
        font-size: 24px;
        line-height: 36px;
    }
}

@media screen and (max-width: 1024px) {
    .linkRow {
        flex-direction: column;
        padding: 0 22px;
    }

    .linkRow .linkCol {
        width: 100%;
        padding: 24px 0 16px;
        border-right: none !important;
        border-bottom: 1px solid #d1d2d3;
    }

    .linkRow .linkCol:last-child {
        border: none;
    }

    .linkRow .linkCol div {
        white-space: normal;
    }

    .container .lowerContainer {
        padding: 0;
    }
}

@media screen and (max-width: 991px) {
    .tourPortalContainer {
        max-width: 100%;
        margin: 0 auto;
    }
    .tourPortalContainer .innerContainer {
        flex-direction: column;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        gap: 32px;
    }

    .tourPortalContainer .innerContainer .inlineItems {
        flex-direction: row;
        align-items: center;
    }

    .chooseLocation h2 {
        max-width: 540px;
        margin: 0 auto;
    }

    .chooseLocation .selectWrapper {
        max-width: 620px;
        margin: 32px auto 0;
    }

    .chooseLocation .btnGreen {
        max-width: 620px;
        margin: 32px auto 0;
    }

    .container .lowerContainer {
        max-width: 720px;
        width: 100%;
        margin: 0 auto;
    }

    .faqContainer {
        max-width: 720px;
        width: 100%;
        margin: 24px auto 0;
    }
    .chooseLocation {
        margin-bottom: 50px;
        max-width: calc(100% - 50px);
    }
}

@media screen and (max-width: 767px) {
    .tourPortalContainer .innerContainer {
        padding: 32px 22px 43px 22px;
        gap: 16px;
    }
}

@media screen and (max-width: 480px) {
    .facilities {
        flex-wrap: wrap;
        gap: 14px;
    }
    .facility {
        width: max-content;
        max-width: 100%;
        margin: 0px;
        font-size: 16px;
        line-height: 22px;
    }
    .inlineItems .selfGuide {
        font-size: 14px;
    }
    .linkRow .linkCol div,
    .chooseLocation h2,
    .faqContainer .faqHeader {
        font-size: 18px;
        line-height: 1.4;
    }
    .tourPortalContainer .innerContainer .locationDetailContainer {
        width: 100%;
    }
}

@media screen and (max-width: 360px) {
    .facility {
        width: 45%;
        max-width: 100%;
    }
}
