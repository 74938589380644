.slideWrapper {
  position: relative;
}

/* .carouselStyle > div {
    height: 232px;
} */

img .slider {
  min-width: 416px;
  height: 100%;
  height: 176px;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 200px;
}

/* @media screen and (max-width: 1366px) {
    .carouselStyle > div {
        height: 130px;
    }
} */