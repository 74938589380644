.horizontal-slider {
    width: 100%;
    height: 50px;
}

.thumb {
    font-size: 12px;
    text-align: center;
    background-color: #3674ee;
    color: white;
    cursor: pointer;
    border: 2px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.track {
    position: relative;
    background: #3b414a;
}

.track.track-1 {
    background: #3674ee;
}

.track.track-2 {
    background: #3b414a;
}

.horizontal-slider .track {
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    height: 5px;
}

.horizontal-slider .thumb {
    top: 1px;
    width: 50px;
    height: 48px;
    line-height: 38px;
}




/* body {
    touch-action: none;
} */
/* touch-action: pan-x pan-y; */