/* / CSS Resetting Start / */
:where(:not(iframe, canvas, img, svg, video):not(svg):not(.outletContainer *):not(.chatBotContainer *):not(.mappedin-map *):not(.appointmentCard *):not(.appointment-listing *):not(.custom-location-selector-wrapper *):not(.mappedin-wrapper *):not(.location-listing *):not(.location-container *):not(.amenities-page *):not(.allowSVG *)) {
    all: unset;
    display: revert;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}

* {
    margin: 0;
}

html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
}

html,
body {
    height: 100%;
    top: 0 !important;
}

body {
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.error {
    border: 1px solid #f5004a !important;
}

/* / CSS Resetting End / */

/* / Globe CSS Start / */
.overflow {
    overflow: hidden;
}

/* / React Calendar CSS Start / */
.react-calendar .react-calendar__navigation {
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile,
.react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    height: 77px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.booked {
    color: #b2b3b5;
    pointer-events: none;
    cursor: not-allowed;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.booked:before {
    position: absolute;
    content: '';
    background: #d1d2d3;
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile[disabled] {
    position: relative;
    color: #bdbdbd;
    cursor: not-allowed;
}

.react-calendar .react-calendar__navigation__label {
    pointer-events: none;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile abbr {
    position: relative;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now {
    color: #e59236;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now:before {
    content: '';
    position: absolute;
    border: 1px solid #e59236;
    height: 55px;
    width: 55px;
    border-radius: 50%;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--rangeBothEnds {
    color: #fff;
}

.react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--rangeBothEnds:before {
    border: 1px solid #e59236;
    background-color: #e59236;
    content: '';
    position: absolute;
    height: 55px;
    width: 55px;
    border-radius: 50%;
}

.react-calendar__navigation .react-calendar__navigation__arrow {
    display: inline-block;
    margin: 0px 12px;
    cursor: pointer;
}

/* / React Modal CSS Start / */
.ReactModal__Overlay {
    background: rgba(25, 28, 33, 0.12) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    z-index: 9999;
}

body.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Content {
    padding: 0px !important;
    border-radius: 8px !important;
    border: none !important;
}

.remove-scrollbar {
    overflow: hidden;
}

/* / Carousel CSS Start / */
.carousel .control-dots .dot {
    margin: 0px 4px !important;
    opacity: 1 !important;
    width: 8px !important;
    height: 8px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.carousel .control-dots .dot.selected {
    width: 18px !important;
    border-radius: 10px !important;
}

.carousel.carousel-slider,
.carousel .slide {
    border-radius: 16px 16px 0 0 !important;
    transform: translateZ(0);
}

.dmNewParagraph {
    height: 600px;
    padding: 15px;
}

.font-size-13,
.size-13,
.size-13>font {
    font-size: 13px !important;
}

.dmNewParagraph[data-version] ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}

.dmNewParagraph ol>li,
.dmNewParagraph ul>li {
    list-style-type: none;
    text-indent: -1.5em;
    margin: 0 1em;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
    -webkit-margin-start: 2em;
    margin-inline-start: 2em;
}

.dmNewParagraph[data-version] ol li:before {
    content: counter(list-0, decimal) '. ';
}

.dmNewParagraph[data-version] ol>li:before,
.dmNewParagraph[data-version] ul>li:before {
    min-width: 1em;
    display: inline-block;
    margin-right: 0.5em;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0.5em;
    margin-inline-end: 0.5em;
    text-align: right;
    text-align: end;
}

.dmNewParagraph[data-version] ol .ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-1;
}

.dmNewParagraph[data-version] ol .ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
}

.dmNewParagraph[data-version] ul>li:before {
    font-weight: 700;
    content: '\2022';
    font-family: Arial;
}

.dmNewParagraph[data-version] .ql-indent-1 {
    padding: 0 1em;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
    -webkit-padding-start: 3em;
    padding-inline-start: 3em;
}

.carousel .thumbs .thumb {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    /* margin-right: 16px; */
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    opacity: 0.5;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: none !important;
    opacity: 1;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.carousel .thumbs-wrapper {
    margin: 16px 0px !important;
}

.carousel .thumb img {
    vertical-align: middle;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}

.carousel .carousel-status {
    top: auto !important;
    right: 15px !important;
    font-size: 12px !important;
    bottom: 0;
}

.carousel .slide img,
.carousel .slide>div,
.carousel .slider-wrapper .slider,
.carousel .slider-wrapper {
    height: 100%;
}

.carousel .slide img {
    -o-object-fit: cover;
    object-fit: cover;
    aspect-ratio: 4/3;
}

.unitCard .carousel {
    height: 120px;
}

.unitCard .carousel.carousel-slider {
    height: 277px !important;
    overflow: hidden;
}

.locationCard .carousel.carousel-slider {
    height: auto !important;
    overflow: hidden;
}

.locationCard .carousel .slide img {
    aspect-ratio: 4/3;
    border-radius: 16px;
}

p.address-heading {
    font-size: 24px;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

/* p.address-heading span:first-child{
    margin-right: 10px;
    margin-top: 10px;
} */
p.address-text {
    font-size: 18px;
    margin-left: 39px !important;
}

.ReactModal__Overlay .ReactModal__Content {
    background-color: transparent !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    overflow: unset !important;
}

.ReactModal__Overlay .ReactModal__Content>div {
    background-color: #fff;
    border-radius: 15px;
}

/* / tour portal start / */
.selectLocationId>div>div>div>span,
.selectLocation>div>div>div>div:last-child>span {
    display: none !important;
}

.selectLocationId>div>div {
    border: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #191c21 !important;
    border-radius: 0 !important;
}

.selectLocation>div>div>div>div:first-of-type {
    padding-left: 25px !important;
}

/* .selectLocation:not(:first-of-type, :last-of-type) > div > div > div > div:first-of-type {
    padding-left: 25px !important;
} */
.selectLocation>div>div>div {
    border: 0 !important;
    box-shadow: none !important;
    background-color: #f4f6f9 !important;
    height: 48px !important;
    border: 1px solid #d1d2d3 !important;
    border-radius: 8px;
}

.selectLocation>div>div>div svg {
    display: none !important;
}

.selectLocationId>div>div>div>div svg {
    visibility: hidden;
    width: 50%;
}

.selectLocation>div>div>div>div>div {
    margin-right: 10px !important;
}

.topSection .selectPlaceholderText {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #191c21;
}

.selectLocation .selectPlaceholderText {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #191c21;
    padding: 8px 0;
    margin-right: 15px;
    padding-right: 15px;
}

/* .unitsWrapper .carousel .slider-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 100%);
} */
.locationCard .carousel .slide {
    position: relative;
    width: 100%;
}

.locationCard .carousel .slide::after,
.unitCardWrapper .carousel .slide::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* border-radius: 16px; */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 100%);
}

/* .guestCardDropdown > div > div  {
    border: 0 !important;
    box-shadow: none !important;
    background-color: #f4f6f9 !important;
    height: 48px !important;
    border: 1px solid #d1d2d3 !important;
    border-radius: 8px;
} */
.guestCardDropdown>div>div:hover {
    box-shadow: none;
    outline: none;
}

.guestCardDropdown>div>div>* svg,
.guestCardDropdown>div>div>* span {
    visibility: hidden;
}

.guestCardDropdown>div>div {
    border: 0 !important;
    box-shadow: none !important;
    background-color: #f4f6f9 !important;
    height: 48px !important;
    border: 1px solid #d1d2d3 !important;
    border-radius: 8px;
    box-shadow: none !important;
}

.guestCardDropdown>div>div>div {
    position: absolute;
}

.creditCard .StripeElement {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #c3c3c3;
}

/* Responsive Start */
@media screen and (max-width: 1280px) {
    p.address-heading {
        font-size: 20px;
    }
}

@media screen and (max-width: 992px) {

    .react-calendar .react-calendar__month-view__days .react-calendar__tile,
    .react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays__weekday {
        height: 55px;
    }
}

@media screen and (max-width: 767px) {
    .ReactModal__Overlay .ReactModal__Content {
        inset: 60% auto -76% 50% !important;
    }

    .ReactModal__Overlay {
        overflow: auto !important;
    }
}

.startEndWrapper>div>div>div:last-child span,
.startEndWrapper>div>div>div:last-child svg {
    display: none;
}

.unitDetailsContainer .carousel .thumbs .thumb {
    margin-right: 0px !important;
}

.slider .slide {
    transition: all ease 3s !important;
    background: transparent !important;
}

.selectWrapper .css-1s2u09g-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #191c21;
}

.selectWrapper .css-1s2u09g-control:hover {
    border-bottom: 1px solid #191c21;
}

.selectWrapper .css-1pahdxg-control,
.selectWrapper .css-1pahdxg-control:focus,
.selectWrapper .css-1pahdxg-control:active,
.selectWrapper .css-1pahdxg-control:focus-visible {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid #191c21 !important;
    border-radius: 0;
}

.selectWrapper .css-1okebmr-indicatorSeparator {
    display: none;
}

.selectWrapper .css-14el2xx-placeholder {
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
    color: #191c21;
}

.disable-scrolling {
    overflow: hidden;
}

.carousel-control {
    border-radius: 20px !important;
}

.onBoardBody .loader {
    border-radius: 18px;
    flex-wrap: nowrap !important;
}

.book-appointment {
    position: fixed;
    inset: 0;
    overflow-y: hidden !important;
    width: initial;
}

.sliderimg .carousel .slide img {
    aspect-ratio: 4/3;
    border-radius: 16px;
}

.terms-modal.ReactModal__Overlay {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    padding: 80px 16px;
    height: 100vh;
    width: 100vw;
}

.terms-modal.ReactModal__Overlay .ReactModal__Content--after-open {
    inset: 50% auto auto 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;

    padding: 20px;
}

@media screen and (max-width:767px) {
    .terms-modal.ReactModal__Overlay .ReactModal__Content--after-open {
        inset: 50% auto auto 50% !important;
    }
}

/* / Responsive Start / */
@media screen and (max-width: 1024px) {
    .unitCard .carousel {
        height: auto;
    }

    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--rangeBothEnds:before,
    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now:before {
        height: 40px;
        width: 40px;
    }

    .carousel .thumbs .thumb {
        padding-right: 10px;
    }

    .carousel .thumbs .thumb {
        margin-right: 2px !important;
    }
}

@media screen and (max-width: 767px) {
    .ReactModal__Overlay .ReactModal__Content {
        inset: 60% auto -76% 50% !important;
    }
}

@media screen and (max-width: 576px) {
    p.address-text {
        font-size: 14px;
        margin-left: 28px !important;
    }

    p.address-heading {
        font-size: 16px;
    }
}

@media screen and (max-width: 415px) {
    .carousel .thumbs-wrapper {
        margin: 16px 0px !important;
    }
}

@media screen and (max-width: 414px) {
    .react-calendar .react-calendar__navigation {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 8px;
    }

    .react-calendar .react-calendar__month-view__days .react-calendar__tile,
    .react-calendar .react-calendar__viewContainer .react-calendar__month-view__weekdays__weekday {
        height: 40px;
        font-size: 12px;
        line-height: 1.2;
    }

    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--rangeBothEnds:before,
    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now:before {
        height: 35px;
        width: 35px;
    }

    .react-calendar .react-calendar__month-view__days .react-calendar__tile abbr {
        font-size: 12px;
    }

    .carousel .thumbs-wrapper {
        margin: 16px 0px 32px !important;
    }

    .carousel .thumbs .thumb {
        margin-right: 8px;
        width: 64px !important;
        height: 50px !important;
    }

    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--rangeBothEnds:before,
    .react-calendar .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--now:before {
        height: 30px;
        width: 30px;
    }
}

.slider-wrapper.axis-horizontal {
    border-radius: 15px;
}

.custom-profile-dropdown__control {
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    border: none !important;
}

.custom-profile-dropdown__control--is-focused {
    box-shadow: none !important;
}

.custom-profile-dropdown__indicators {
    display: none !important;
}

.custom-profile-dropdown__value-container {
    display: flex !important;
    padding: 0 !important;
    min-height: 46px;
    height: 46px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .01rem;
}

.custom-profile-dropdown__single-value,
.custom-profile-dropdown__single-value--has-value {
    width: 100%;
    min-height: 46px;
    height: 46px;
    padding: 10px 10px 10px 16px;
    margin-left: 0 !important;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: .01rem;
    margin-right: 0 !important;
    border-radius: 8px !important;
}

.custom-profile-dropdown__input-container {
    position: absolute;
    width: calc(100% - 56px);
    height: 46px;
    top: 0;
    left: 16px;
    right: 0;
    bottom: 0;
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    z-index: 99;
}

.custom-profile-dropdown__input {
    min-width: 40px !important;
    display: flex;
}

.profile-custom-dropdown {
    position: relative;
    z-index: 0;
    border: 1px solid #d1d2d3;
    border-radius: 8px !important;
    width: 100%;
    height: 46px;
    background-color: #f4f6f9 !important;
    position: relative;
}

.custom-dropdown-indicator {
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer;
}

.ReactModal__Overlay.custom-webcam-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;
}

.custom-webcam-modal .ReactModal__Content {
    position: absolute;
}

@media screen and (max-width:767px) {
    .custom-webcam-modal .ReactModal__Content {
        position: fixed !important;
        inset: 5% auto 0 50% !important;
        transform: translate(-50%, 0%) scale(0.9) !important;
    }
}

.skiptranslate {
    display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none !important;
}


/* amenity swiper css starts here */
.amenity-slider {
    max-height: 180px;
    border-radius: 8px;
    overflow: hidden;
}

.amenity-slider .slick-list {
    height: 100%;
}

.amenity-slider video,
.amenity-slider img {
    height: 100%;
    object-fit: cover;
}

.amenity-slider>div * {
    height: 100%;
}

.amenity-slider .slick-prev {
    left: 8px;
    z-index: 2;
}

.amenity-slider .slick-next {
    right: 8px;
    z-index: 2;
}

.mappedin-2d-entities {
    z-index: 9;
}

.amenity-slider .slick-dots {
    position: absolute;
    z-index: 9;
    bottom: 8px;
}

.amenity-slider .slick-dots li {
    width: 12px;
    height: 12px;
    margin: 6px 2px;
}

.amenity-slider .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 2px;
}

.amenity-slider .slick-dots li button:before {
    font-family: slick;
    font-size: 4px;
    line-height: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '•';
    text-align: center;
    opacity: .5;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.amenity-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffffff;
}