.container {
    height: calc(100vh - 122px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.container .textLg {
    font-size: 8vw;
    line-height: 1;
    color: transparent;
    font-weight: 700;
    -webkit-text-stroke: 2px #e59236;
}
.container .notFoundImage {
    width: 100%;
    max-width: 120px;
}
.container p {
    font-size: 2vw;
    line-height: 1.2;
    margin-top: 16px;
    margin-bottom: 30px;
}
.container .btn {
    background-color: #e59236;
    border-radius: 8px;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

@media screen and (max-width: 767px) {
    .container .textLg {
        font-size: 15vw;
    }
    .container p {
        font-size: 4vw;
    }
}

@-webkit-keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -512px -512px;
    }
}
@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -512px -512px;
    }
}
