.fluid {
    background: #191c21;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    z-index: 99;
}

.outerContainer {
    /* width: 100%; */
    max-width: 1470px;
    padding: 0px 15px;
    margin: auto;
    height: 40px;
}

.flexContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.link {
    color: #fff;
}

.link a {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.link img {
    margin-right: 8px;
}

/* Responsive Start */
@media screen and (max-width: 1366px) {
    .outerContainer {
        max-width: 90%;
    }
}

@media screen and (max-width: 1024px) {
    .outerContainer {
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 767px) {
    .fluid {
        display: none;
    }
}