.chatBotContainer {
  position: fixed;
  bottom: 147px;
  right: 32px;
  z-index: 999;
}

.chatBotContainer .chatBotRootContainer {
  position: absolute;
  right: 0%;
  bottom: 0px;
  width: 353px;
  height: 486px;
  overflow: hidden;
  transition: width 0.8s ease-in-out, border-radius 0.3s 0.7s ease-in-out, height 1s 1s ease-in-out, background-color 1s 1s ease-in-out;
  border-radius: 1px;
}

.chatBotContainer .chatBotRootContainer.chatBotSmallContainer {
  width: 60px;
  height: 60px;
  overflow: hidden;
  transition: height 0.8s ease-in-out, width 0.8s 1s ease-in-out, border-radius 1s 1s ease-in-out;
}

.chatBotContainer .chatBotRootContainer .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.chatBotContainer .chatBotRootContainer .container .chatBotHeader .chatClose {
  color: rgba(255, 255, 255, 0.1);
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: flex-end;
}


.chatBotContainer .chatBotRootContainer.chatBotSmallContainer .container .chatBotHeader .chatClose {
  transition: all 1s ease-in-out;
}

.chatClose svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

.chatBotRootContainer.chatBotSmallContainer .chatBotHeader {
  background-color: #fff;
  border-radius: 140px 140px 140px 0;
  border: 2px solid #3674EE;
  padding: 10px;
  position: relative;
  transition: all 1s ease-in-out;
}

.chatBotHeader .botLeftContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.chatBotHeader .botInformation {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chatBotHeader .botInformation span {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 0.7s steps(30, end) forwards, blink 0.7s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
    /* Full width based on the text */
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
    /* Cursor blinking effect */
  }
}

span.botName {
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  font-weight: 700;
}

span.botInfo {
  color: #C7D4EE;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.chatBotRootContainer .chatBotHeader {
  flex-basis: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  transition: padding 1s 1s linear, height 0s ease-in-out;
  cursor: pointer;
  background-color: #51AB86;
  width: 100%;
  border-radius: 16px 16px 0 0;
  position: relative
}

.chatBotHeader .chatAvatar {
  transition: all 0.5s ease-in-out;
  min-width: 32px;
}

.chatContainer .dotsWrapper {
  position: relative;
  width: 100%;
  height: 41px;
  margin-left: auto;
  margin-right: auto;
  bottom: 2px;
  left: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.chatBotRootContainer.chatBotSmallContainer .chatBotHeader .headerDotsWrapper {
  position: absolute;
  width: 100%;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  bottom: 2px;
  left: 3px;
  display: flex;
  gap: 4px;
}

.chatBotBody::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 4px;
  background-color: transparent;
}

.chatBotBody::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  background-color: transparent;
}

.chatBotBody::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: none;
  background-color: #51AB86;
}

.chatBotRootContainer.chatBotSmallContainer .chatBotHeader .headerDotsWrapper .dot,
.chatContainer .dotsWrapper .dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #D3D3D3;
  animation: singleWave 1.5s linear infinite;
}



.chatBotRootContainer.chatBotSmallContainer .chatBotHeader .headerDotsWrapper .dot:nth-child(2),
.chatContainer .dotsWrapper .dot:nth-child(2) {
  animation-delay: 0.5s;
}

.chatBotRootContainer.chatBotSmallContainer .chatBotHeader .headerDotsWrapper .dot:nth-child(3),
.chatContainer .dotsWrapper .dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes singleWave {

  0%,
  66% {
    background: #D3D3D3;
  }

  33% {
    background: #0000FF;
  }
}

.chatBotRootContainer.chatBotSmallContainer .chatBotFooter {
  padding: 0;
  flex-basis: 0px;
  transition: padding 2s ease-in-out, flex-basis 1s 1s ease-in-out, height 1s 1s ease-in-out;
}

.chatBotRootContainer .chatBotBody {
  flex-basis: 100%;
  height: 353px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  background: #FFFFFF;
  width: 100%;
}

.chatBotBody .bot {
  flex-basis: 100%;
  margin: 14px 16px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: fit-content;
  gap: 8px;
}

.chatBotBody .bot .systemMessage {
  background-color: #F8F8F8;
  border-radius: 8px 8px 8px 4px;
  padding: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #191C21;
  white-space: pre-wrap;
}


.chatResponseContainer .botProfile .name,
.chatBotBody .bot .name {
  font-weight: 600;
  margin: 4px;
  color: #334155;
  font-size: 12px;
}


.chatBotRootContainer.chatBotSmallContainer .chatBotFooter {
  border-top: none;
}

.chatBotRootContainer .chatBotFooter {
  display: flex;
  height: 75px;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-around;
  transition: height 1s 1s ease-in-out, flex-basis 1s 1s;
  width: 100%;
  border-radius: 0 0 16px 16px;
  padding: 16px 16px 27px;
  border-top: 1px solid #D1D2D3;
  background-color: #FFFFFF;
}

.chatBotFooter .inputHolder {
  border: none;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 32px;
  height: 32px;
  width: 100%;
}

.chatBotFooter .inputHolder form {
  margin: 0;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
}

.chatBotFooter .inputHolder form .inputWrapper {
  width: calc(100% - 46px);
}

.chatBotFooter .inputHolder form input {
  border: none;
  background: none;
  line-height: 1.25rem;
  height: 1.5rem;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
}

.chatBotFooter .inputHolder button {
  border-radius: 26px;
  padding: 4px;
  color: white;
  border: none;
  background-color: #51AB86;
  width: 32px;
  height: 32px;
  cursor: pointer;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatBotFooter .inputHolder button:disabled {
  background-color: #F0F0F0;
}

.chatBotBody .chatContainer {
  flex-basis: 100%;
  margin: 14px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}


.chatContainer .userQuery {
  max-width: 292px;
  padding: 10px;
  border-radius: 8px 8px 8px 4px;
  word-break: break-word;
  background-color: #F8F8F8;
  display: inline-flex;
  margin-left: auto;
}

.chatContainer .chatBotAnswer {
  padding: 4px 8px;
  border-radius: 5px;
  word-break: break-word;
  background-color: white;
  white-space: pre-wrap;
}

.chatResponseContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 8px;
}

.chatResponseContainer .botProfile img {
  min-width: 41px;
}

.chatResponseContainer .chatBotAnswer {
  padding: 10px;
  background-color: #F8F8F8;
  font-size: 16px;
  line-height: 24px;
  color: #191C21;
  max-width: 214px;
}

@media screen and (max-width:767px) {
  .chatBotContainer {
    right: 12px;
    bottom: 75px;
  }

  .chatBotContainer .chatBotRootContainer {
    bottom: 0;
    right: 0;
    width: calc(100vw - 24px);
    max-height: calc(100dvh - 200px);
  }
}