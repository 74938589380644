.fluid {
    background: #191c21;
    position: fixed;
    bottom: 0;
    z-index: 99999;
    width: 100%;
}
.outerContainer {
    width: 100%;
    max-width: 1410px;
    padding: 0px 15px;
    margin: auto;
    height: 40px;
}
.flexContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.link,
.copyright {
    color: #fff;
}
.link a {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-decoration: none;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}
.copyright {
    font-size: 14px;
    line-height: 32px;
}
.link img {
    margin-right: 8px;
}

/* Responsive Start */
@media screen and (max-width: 1024px) {
    .fluid {
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 999;
    }
    .link a,
    .copyright {
        font-size: 12px;
    }
}
@media screen and (max-width: 420px) {
    .link a,
    .copyright {
        font-size: 9px;
    }
    .link img {
        margin-right: 5px;
        width: 15px;
    }
}
