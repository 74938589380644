.card {
    background: #ffffff;
    -webkit-box-shadow: 5px 10px 32px rgba(65, 65, 65, 0.09);
    box-shadow: 5px 10px 32px rgba(65, 65, 65, 0.09);
    border-radius: 16px;
    width: 100%;
}
.cardBody {
    padding: 16px;
    cursor: pointer;
}
.cardBody .bodyTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 8px;
}
.cardBody .timeZone {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 16px;
}
.cardBody .address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.cardBody .address img {
    display: inline-block;
    width: 20px;
}
.cardBody .address span {
    font-size: 14px;
    line-height: 26px;
    display: inline-block;
    margin-left: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cardBody .description {
    margin-top: 16px;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 1.4;
    color: #b2b3b5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* Responsive Start */
@media screen and (max-width: 1366px) {
    .cardBody .bodyTitle {
        font-size: 16px;
        line-height: 1.2;
    }
    .cardBody .timeZone {
        font-size: 12px;
        line-height: 1.4;
    }
}
@media screen and (max-width: 1024px) {
    .cardBody .address img {
        width: 15px;
    }
    .cardBody .address span {
        margin-left: 5px;
        font-size: 12px;
        line-height: 1.4;
    }
}

.hidden {
    pointer-events: none;
}