.modalWrapper {
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.termsAndConditionsWrapper {
  max-height: calc(100vh - 80px);
}

.termsHeader {
  position: relative;
  padding-bottom: 16px;
}

.termsdetail {
  height: auto;
  overflow: auto;
}

.termsdetail::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.termsdetail::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.termsdetail::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #FFF;
  background-image: -webkit-gradient(linear,
      40% 0%,
      75% 84%,
      from(#51AB86),
      to(#19911D),
      color-stop(.6, #51AB86))
}

.closeModal {
  position: absolute;
  right: 0;
  top: 8px;
  cursor: pointer;
}

.font {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.closebtn {
  cursor: pointer;
  justify-content: center;
  background-color: rgb(192, 192, 192);
  text-align: center;
  margin: auto;
  display: block;
  padding: 6px 9px;
  border-radius: 8px;
  margin-top: 10px;
}

.h1,
.h2,
.h3 {
  font-weight: 700;
}

.h1 {
  font-size: 16px;
}

.font {
  font-weight: 300;
  font-size: 13px;
}

.backbtn {
  text-align: center;
}

.back {
  background-color: rgb(229, 149, 53);
  display: inline-block;
  margin: 0 auto;
  padding: 10px 70px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  border: 3px solid #f1ac44;
  margin: 30px 0;
  cursor: pointer;
}

.accept {
  background-color: #51ab86;
  display: inline-block;
  margin: 0 auto;
  padding: 10px 70px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  border: 3px solid #77c2a3;
  margin: 30px 0;
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width:767px) {
  .backbtn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;
  }

  .accept,
  .back {
    margin: 0 auto 0;
    max-width: 240px;
    width: 100%;
  }
}