.changePasswordContainer {
  height: calc(100vh - 122px);
}

.changePasswordContainer .innerContainer {
  max-width: 1470px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 15px 50px;
}

.changePasswordContainer .innerContainer .formWrapper {
  background: #ffffff;
  border-radius: 16px;
  max-width: 480px;
  width: 100%;
  margin: auto;
  padding: 45px 40px;
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  position: relative;
  z-index: 9;
  box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07);
  display: flex;
  flex-direction: column;
}


.innerContainer .formWrapper h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #191c21;
  margin: 0px 0px 24px;
  font-family: 'Rubik', sans-serif;
  text-align: left;
  width: 100%;
}

.formWrapper .fieldWrapper {
  text-align: left;
  margin-bottom: 24px;
  width: 100%;
}

.fieldWrapper label {
  color: #191c21;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 16px;
}

.invalidCred {
  color: #f5004a;
  display: inline-block;
  font-size: 12px;
  line-height: 1.4;
  position: absolute;
  width: 100%;
  padding-top: 4px;
}

.newPasswordFieldWrapper .invalidCred {
  position: relative;
}

.fieldWrapper input {
  width: 100%;
  border: 1px solid #d1d2d3;
  border-radius: 8px;
  height: 48px;
  padding: 10px 16px 10px 14px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  font-family: 'Rubik', sans-serif;
  background: #f4f6f9;
}

.passwordWrapper {
  position: relative;
}

.iconToggle {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.iconToggle img {
  cursor: pointer;
  width: 18px;
}

.formWrapper .buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.formWrapper .buttonContainer .submitWrapper button {
  background: #51ab86;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  line-height: 28px;
  max-width: 375px;
  padding: 10px 15px;
  text-align: center;
  width: auto;
}

.formWrapper .buttonContainer .submitWrapper button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.formWrapper .buttonContainer .cancelWrapper button {
  border: 1px solid #51ab86;
  border-radius: 8px;
  color: #51ab86;
  background-color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  line-height: 28px;
  max-width: 375px;
  padding: 10px 15px;
  text-align: center;
  width: auto;
}

@media screen and (max-width:575px) {
  .changePasswordContainer .innerContainer {
    padding: 0;
  }

  .changePasswordContainer .innerContainer .formWrapper {
    max-width: 100%;
    box-shadow: none;
    padding-left: 24px;
    padding-right: 24px;
  }
}