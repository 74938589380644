.unitCard {
    position: relative;
    cursor: pointer;
}
.unitCard .price {
    position: absolute;
    z-index: 9;
    background: #e59236;
    border-radius: 30px;
    padding: 0px 9px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
    color: #fff;
    height: 26px;
    display: inline-flex;
    right: 8px;
    top: 8px;
    align-items: center;
    justify-content: center;
}
.cardInfo {
    padding: 16px;
    width: 100%;
    cursor: pointer;
}
.cardInfo .cardTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /* justify-content: space-between; */
}
.cardInfo .cardTitle h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    text-transform: capitalize;
    color: #191c21;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    /* margin-left: 10px; */
}
/* .nickName {
    margin-left: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
} */
.cardInfo .cardTitle span {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-transform: capitalize;
    color: #e59236;
}
.cardInfo .facilities {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 16px;
}
.cardInfo .facilities .facility {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.cardInfo .facilities .facility img {
    width: 16px;
}
.cardInfo .facilities .facility:last-child {
    margin-right: 0px;
}
.cardInfo .facility span {
    display: inline-block;
    font-size: 14px;
    line-height: 1.2;
    margin-left: 8px;
}
.unitCard .priceNote{
    display: inline-block;
    margin-top: 16px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    display: flex;
    font-style: italic;
    color: #707B7C;
}
.unitCard .priceNote span{
    margin-right: 3px;
}
/* Responsive Start  */
@media screen and (max-width: 375px) {
    .cardInfo .facility span {
        margin-left: 4px;
        font-size: 12px;
        line-height: 1.2;
    }
    .cardInfo .facilities {
        margin-top: 8px;
    }
}
