.container {
    width: 100%;
    max-width: 1470px;
    padding: 30px 15px 50px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 122.5px);
}

.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.2;
    text-transform: capitalize;
    color: #191c21;
    padding-bottom: 15px;
}

.locationCards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0px -15px;
}

.cardWrapper {
    width: 33.33%;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 20px !important;
}

.cardWrapper>div>div>div>div>div>ul>li>img,
.hidden>div>div>div>div>div>ul>li>img {
    border-radius: 20px 20px 0 0 !important;
    aspect-ratio: 4/3;
}

.hidden {
    pointer-events: none;
    cursor: none;
    width: 33.33%;
    padding: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 20px !important;
}

.locationCard .carousel .slide {
    border-radius: 16px 16px 0 0 !important;
}

.inlineItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.inlineItems .timeZoneWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 420px;
    width: 100%;
}

.timeZoneWrapper .timeZoneSelect {
    width: calc(100% - 135px);
}

.timeZoneWrapper label {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    margin-bottom: 16px;
    margin: 0;
}

.locationCards {
    margin-top: 8px;
    max-height: calc(100% - 62px);
    overflow-y: auto;
}

.locationCards::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
}

.locationCards::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

.locationCards::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
}

.noPropertyWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 16px;
    flex-direction: column;
    height: calc(100% - 54px);
}

.noPropertyWrapper p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #191c21;
}

/* Dropdown style starts here */

.customReactSelectDropdown {
    height: 48px !important;
    font-family: Rubik;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px !important;
    position: relative;
    min-width: 280px !important;
    border: 1px solid #191c21;
}

.placeholder {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 200% */
    color: #B2B3B5;
}

.customReactSelectDropdown:focus-within {
    box-shadow: none !important;
}

.customReactSelectDropdown .boxes {
    padding: 8px;
    display: flex;
    gap: 8px;
    position: relative;
    cursor: pointer;
}

.customReactSelectDropdown .boxes:hover {
    background-color: rgba(54, 116, 238, 0.08);
}

.customReactSelectDropdown .boxes input[type='checkbox'],
.customReactSelectDropdown .boxes input[type='radio'] {
    width: 18px;
    height: 18px;
    position: relative;
    top: 4px;
    border-radius: 4px;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding-right: 8px;
}


.inlineItems {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 0 16px 8px;
}

.actionButtons .boxes:hover {
    background-color: transparent;
}

.actionButtons .clearBtn {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 185.714% */
    text-decoration-line: underline;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    color: #b2b3b5;
    cursor: pointer;
}

.customReactSelectDropdown .boxes label {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-transform: capitalize;
    margin: 0;
    width: 100%;
    cursor: pointer;
}

.customReactSelectDropdown .listContainer {
    max-height: 220px;
    height: 100%;
    overflow-y: auto;
    width: 100%;
}

.listContainer::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    position: absolute;
    right: -10px;
    z-index: 2;
    border-radius: 40px;
    background-color: #d1d2d3;
}

.listContainer::-webkit-scrollbar * {
    background: black;
}

.listContainer::-webkit-scrollbar-thumb {
    background: #3674ee;
    width: 4px;
    border-radius: 40px;
    height: 4px;
}

/* Dropdown style ends here */

/* / Responsive Start / */
@media screen and (max-width: 1366px) {
    .container {
        max-width: 90%;
    }

    .title {
        font-size: 32px;
        line-height: 1.2;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        max-width: 100%;
        padding: 30px;
    }

    .cardWrapper {
        width: 50%;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 7px;
    }

    .hidden {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {

    .container {
        height: calc(100vh - 72.3px);
    }

    .inlineItems {
        flex-direction: column;
        align-items: flex-start;
    }

    .noPropertyWrapper {
        height: calc(100% - 119px);
    }

    .locationCards {
        max-height: calc(100% - 119px);
        overflow-y: auto;
    }

    .inlineItems .timeZoneWrapper {
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
    }

    .inlineItems .timeZoneWrapper .timeZoneSelect {
        width: 100%;
    }
}

@media screen and (max-width: 575px) {

    .cardWrapper,
    .hidden {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .container {
        max-width: 100%;
        padding: 30px 15px 15px;
    }
}