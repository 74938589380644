.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 32px;
}
.header .back {
    min-height: 48px;
    min-width: 48px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
}
.header .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #191c21;
}
.header .back img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.accountBody form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.fieldWrapper {
    margin-bottom: 24px;
    width: 50%;
    max-width: 48%;
}
.fieldWrapper:last-child {
    margin-bottom: 0px;
}
.fieldWrapper label {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    color: #191c21;
    margin-bottom: 16px;
    display: block;
}
.fieldWrapper input {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    height: 48px;
    padding: 10px 45px 10px 14px;
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    background: #f4f6f9;
}
.passwordWrapper {
    position: relative;
}
.passwordToggle {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    right: 16px;
}
.passwordToggle img {
    width: 19px;
    -o-object-fit: contain;
    object-fit: contain;
}
.btnWrapper {
    margin-top: 8px;
    text-align: center;
    width: 100%;
}
.btnWrapper button {
    max-width: 375px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    height: 48px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.btnWrapper button.disable {
    background: #d1d2d3;
    color: #8c8d90;
}
.btnWrapper button.success {
    color: #ffffff;
    background: #51ab86;
}

/* Responsive Start */
@media screen and (max-width: 767px) {
    .header .title {
        font-size: 24px;
        line-height: 1.2;
    }
    .passwordToggle img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    .header .back {
        min-height: 35px;
        min-width: 35px;
        background-color: #fff;
    }
    .fieldWrapper label {
        font-size: 14px;
        line-height: 1.2;
    }
}

@media screen and (max-width: 414px) {
    .fieldWrapper {
        width: 100%;
        max-width: 100%;
    }
    .header .title {
        font-size: 18px;
        line-height: 1.4;
    }
    .fieldWrapper input {
        height: 40px;
    }
    .btnWrapper button {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-size: 16px;
        line-height: 1.2;
        height: 40px;
    }
}
