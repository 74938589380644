.container {
    width: 100%;
    max-width: 1470px;
    padding: 30px 15px 50px;
    margin-left: auto;
    margin-right: auto;
}

.container>.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 24px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: none;
}

.sortWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
    padding: 8px 12px;
    background-color: #272c34;
    border-radius: 25px;
    display: none;
}

.iconFilter {
    /* -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1); */
    width: 20px;
    height: 20px;
    -o-object-fit: contain;
    object-fit: contain;
}

.sortWrapper img {
    display: inline-block;
    margin-right: 4px;
}

.cardWrapper {
    width: 25%;
    padding: 15px;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.card {
    background: #ffffff;
    -webkit-box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.04);
    box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    width: 100%;
}

.unitsWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 30px -15px -15px 0px;
}

.overflowWrapper::-webkit-scrollbar {
    display: none;
}

.filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px -8px;
    overflow-x: auto;
    overflow-y: visible;
    justify-content: center;
}

.filters .filter {
    margin: 0px 8px;
}

.filters .filter button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #eef4ff;
    border-radius: 16px;
    color: #3f4246;
    height: 48px;
    padding: 14px 16px;
    cursor: pointer;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    font-weight: 600;
}

.filters .filter button:hover {
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    background: #3674ee;
    color: #fff;
}

.filters .filter button.active {
    background: #3674ee;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    color: #fff;
}

.filters .filter button img {
    display: inline-block;
    margin-right: 8px;
    filter: brightness(0%);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.filters .filter button:hover img,
.filters .filter button.active img {
    filter: brightness(0) invert(1);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.filterContainer {
    background: #fff;
    border-radius: 16px;
    margin-top: 8px;
    position: absolute;
    z-index: 99;
    /* / padding: 16px; / */
    box-shadow: 5px 10px 12px rgb(0 0 0 / 4%);
}

.filterContainer.show {
    display: inherit;
}

.filterContainer.hide {
    display: none;
}

.checkboxContainer {
    margin: 8px 0px;
    padding: 0px 12px;
}

.checkboxContainer input[type='checkbox'] {
    display: none;
}

.checkboxContainer input[type='checkbox']+label {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 26px;
    color: #3f4246;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkboxContainer input[type='checkbox']+label:last-child {
    margin-bottom: 0;
}

.checkboxContainer input[type='checkbox']+label:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid #3f4246;
    position: absolute;
    left: 0;
    border-radius: 4px;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.checkboxContainer input[type='checkbox']:checked+label:before {
    width: 10px;
    top: 0px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rangeSliderWrapper {
    width: 350px;
    padding: 12px;
}

.filterContainer {
    max-height: 196px;
    overflow-y: auto;
    padding: 8px 0;
}

.filterContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
    position: absolute;
    right: -10px;
    z-index: 2;
}

.filterContainer::-webkit-scrollbar * {
    background: transparent;
}

.filterContainer::-webkit-scrollbar-thumb {
    background: transparent;
    width: 0;
    height: 0;
}

.filtersList li {
    font-size: 14px;
    line-height: 26px;
    color: #3f4246;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    cursor: pointer;
    padding: 0px 12px;
    height: 26px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 16px;
    margin: 4px 4px;
}

.filtersList li.sortBy {
    background: #3674ee;
    color: #fff;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.filtersList li:hover {
    background-color: #3674ee;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    color: #fff;
}

.resetFilter {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #191c21;
    cursor: pointer;
    margin: 0px 8px;
}

.resetFilter img {
    width: 24px;
    display: inline-block;
    margin-right: 4px;
    filter: brightness(0%);
}

.filters::-webkit-scrollbar {
    display: none;
}

.UnitGreetings {
    text-align: center;
    margin-bottom: 50px;
}

.UnitGreetings .greetingTitle {
    font-size: 32px;
    line-height: 1.2;
    color: #191c21;
}

.UnitGreetings .greetingTitle span {
    font-weight: 600;
}

.UnitGreetings .greetingDesc {
    color: #191c21;
    max-width: 415px;
    margin: auto;
}

.UnitGreetings .titleWrapper {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
    align-items: baseline;
}

.UnitGreetings .titleWrapper .sortWrapper {
    color: #fff;
    margin-left: 15px;
    width: 100%;
    max-width: max-content;
}

/* unitCardOuter   start */

.unitCardOuter .unitCard {
    position: relative;
    /* height: 424px; */
    max-width: 1440px;
    margin-top: 25px;
    display: flex;
}

.unitCardOuter .unitCard>div:first-child {
    width: 55%;
}

.unitCardOuter .unitCard>div:last-child {
    width: 45%;
}

.unitCardOuter .unitCard li img::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 100%);
}

.unitCardOuter .iconAddressWrapper {
    display: inline-block;
    width: 24px;
    height: 24px;
    z-index: 9;
    flex: 0 0 24px;
    margin-top: 6px;
    margin-right: 15px;
}

.unitCardOuter .iconAddressWrapper img {
    filter: brightness(0%);
}

.unitCardOuter .locationInfo {
    position: relative;
    display: block;
    /* text-align: center; */
    padding: 32px;
    margin-left: 32px;
    /* position: absolute;
    bottom: 37px;
    left: 39px;
    color: white;
    z-index: 2;
    font-size: 16px;
    line-height: 26px; */
}

.unitCardOuter .locationInfo>p:first-child {
    padding-bottom: 12px;
    font-weight: 600;
}

.unitCardOuter .locationInfo>p {
    max-width: 1000px;
    margin: auto;
}

.unitCardOuter .locationInfo h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    padding-top: 18px;
    padding-bottom: 4px;
}

.unitCardOuter .locationInfo .timeZone {
    font-size: 14px;
    line-height: 26px;
    padding-left: 8px;
}

.noUnits {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    width: 100%;
}

.noUnitsFoundText {
    margin-top: 16px;
}

.timeZoneWrapper {
    display: flex;
    gap: 10px;
}

.timeZoneWrapper span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #191c21;
}

.disclaimer {
    margin-top: 12px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #191c21;
    display: flex;
    gap: 10px;
}

.disclaimer p {
    max-width: calc(100% - 34px);
}

/* / Responsive Start / */
@media screen and (max-width: 1366px) {
    .container {
        max-width: 90%;
    }

    .container>.title {
        font-size: 32px;
        line-height: 1.2;
    }

    .filters .filter button {
        font-size: 12px;
        line-height: 1.4;
        height: 40px;
    }

    .filters .filter button img {
        width: 16px;
        height: 16px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .resetFilter {
        font-size: 14px;
        line-height: 1.4;
    }

    .resetFilter img {
        width: 20px;
    }

    .cardWrapper {
        width: 33.33%;
    }

    .unitCardOuter .locationInfo {
        padding: 24px;
    }
}

@media screen and (max-width: 1024px) {
    .cardWrapper {
        width: 50%;
    }

    .UnitGreetings .titleWrapper {
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .UnitGreetings {
        text-align: left;
    }

    .UnitGreetings .greetingDesc {
        max-width: 100%;
    }

    .UnitGreetings {
        margin-bottom: 30px;
    }

    .container>.title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .sortWrapper {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .overflowWrapper {
        display: none;
    }

    .container {
        max-width: 90%;
        margin: 0 auto;
        padding: 30px 15px 50px;
    }

    .container>.title {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 8px;
    }

    .filters {
        display: none;
    }

    .unitCardOuter .unitCard {
        flex-wrap: wrap;
    }

    .unitCardOuter .unitCard>div {
        width: 100% !important;
    }

    .unitCardOuter .iconAddressWrapper {
        margin-top: 3px;
    }

    .unitCardOuter .locationInfo {
        padding: 24px 0px;
    }
}

@media screen and (max-width: 576px) {
    .cardWrapper {
        width: 100%;
    }

    .rangeSliderWrapper {
        width: 250px;
    }

    .UnitGreetings .greetingTitle {
        font-size: 22px;
        line-height: 1.2;
    }

    .UnitGreetings .greetingDesc {
        font-size: 14px;
        line-height: 1.4;
    }

    .unitCardOuter .iconAddressWrapper {
        width: 18px;
        height: 18px;
        flex: 0 0 18px;
        margin-right: 10px;
    }

}

@media screen and (max-width: 415px) {
    .container {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
    }

    .UnitGreetings {
        margin-bottom: 16px;
    }

    .unitCardOuter .locationInfo {
        padding: 0px;
    }
}

@media screen and (max-width: 375px) {
    .cardInfo .facility span {
        margin-left: 4px;
        font-size: 12px;
        line-height: 1.2;
    }

    .cardInfo .facilities {
        margin-top: 8px;
    }
}

@media screen and (max-width: 1051px) {
    .unitCardOuter .locationInfo {
        margin: 0;
    }
}

@media screen and (max-width: 681px) {
    .unitCardOuter .locationInfo h6 {
        font-size: 18px;
        line-height: 26px;
        padding-top: 8px;
        padding-bottom: 4px;
    }

    .unitCardOuter .locationInfo .timeZone {
        font-size: 14px;
        line-height: 26px;
        padding-left: 8px;
    }
}

@media screen and (max-width: 400px) {
    .unitCardOuter .locationInfo h6 {
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        padding-top: 4px;
        padding-bottom: 3px;
    }

    /* .unitCardOuter .locationInfo p {
        font-size: 12px;
        line-height: 16px;
    }
    .unitCardOuter .locationInfo p span {
        line-height: 18px;
        font-size: 12px;
    } */
    .unitCardOuter .locationInfo .timeZone {
        font-size: 14px;
        line-height: 26px;
        padding-left: 8px;
    }

    .unitCardOuter .locationInfo .timeZone {
        font-size: 12px;
    }
}