.dashboardWrapper {
    background-color: #fff;
    border-radius: 16px;
}

.dashboardWrapper .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    padding: 32px;
    border-bottom: 1px solid #191c21;
}

.dashboardBanner img {
    margin: 30px auto 12px;
}

.scheduleInfo p {
    text-align: center;
    font-size: 18px;
    line-height: 32px;
    padding: 0 12px;
}

.schedule {
    padding-top: 24px;
    padding-bottom: 20px;
    text-align: center;
}

.scheduleIcon {
    width: 48px;
    height: 48px;
    background: #eefff8;
    border-radius: 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 8px;
}

.schedule img {
    width: 24px;
}

.schedule .date,
.schedule .time {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 40px;
}

.schedule .date span,
.schedule .time span {
    font-size: 16px;
    line-height: 26px;
    display: inline-block;
    margin: 0px 8px;
}

.disclaimer {
    margin-top: 12px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: #191c21;
    display: flex;
    gap: 10px;
    max-width: 780px;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px 24px;
}

.disclaimer p {
    max-width: calc(100% - 34px);
    padding: 0;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
}

.disclaimer svg {
    margin-top: 2px;
}

.btnWrapper {
    padding: 0px 32px 32px;
    text-align: right;
}

.btnWrapper button {
    padding: 10px 32px;
    background: #51ab86;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
}

.confirmTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #191c21;
    margin-bottom: 19px;
}

/* Responsive Start */
@media screen and (max-width: 767px) {
    .scheduleInfo p {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 1.4;
    }

    .schedule .date,
    .schedule .time {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0px;
    }

    .scheduleIcon {
        margin-right: 0px;
        margin-bottom: 8px;
    }

    .dashboardWrapper {
        overflow-y: auto;
    }

    .dashboardBanner {
        height: 142px;
    }

    .dashboardBanner img {
        width: auto;
        max-width: 100%;
        height: 100px;
    }

    .btnWrapper {
        text-align: center;
    }
}

@media screen and (max-width: 414px) {
    .scheduleInfo p {
        font-size: 15px;
    }

    .schedule .date span,
    .schedule .time span {
        font-size: 15px;
        line-height: 1.4;
        margin: 2px 8px;
    }

    .btnWrapper button {
        padding: 8px 32px;
        font-size: 16px;
        line-height: 1.2;
        height: 40px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .confirmTitle {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 15px;
    }
}