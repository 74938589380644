.container {
  position: relative;
  background: #191C21;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 11px 8px 11px 0;
  color: #fff;
  font-size: 1.2em;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.swiperWrapper {
  display: flex;
  position: relative;
  padding-right: 25px;
}

.swiperWrapper .swipeIcon {
  right: 0;
  position: absolute;
}

.text {
  /* position: absolute; */
  width: 100%;
  text-align: center;
  /* left: 0;
  top: 50%; */
  height: 100%;
  /* transform: translateY(-50%); */
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.text .swipeText {
  opacity: 0.30;
}

.amazonKeyWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.amazonKeyWrapper img {
  width: 30px;
  height: 30px;
}

.completedOverlay {
  width: 100%;
  height: 100%;
  background: #191C21;
  position: absolute;
  left: -100%;
  top: 50%;
  opacity: 0;
  transition: opacity 2.25s ease-in-out, transform 1.5s ease-in-out;
  transform: translateY(-50%);
}

.completedOverlay.completed {
  transform: translate(100%, -50%);
  opacity: 1;
}

.completedOverlay .OpeningText {
  color: white;
  padding: 11px 16px;
  align-content: center;
  height: 100%;
  text-align: center;
}

.control {
  max-width: 100%;
  width: auto;
  height: 100%;
  background: #191C21;
  cursor: grab;
  position: absolute;
  top: 0;
  left: 8px;
  z-index: 2;
  transform: translateX(0);
  transition: transform 0.2s ease;
}

.control:active {
  cursor: grabbing;
}