.loader {
    position: fixed;
    min-height: 100vh;
    top: 0px;
    width: 100vw;
    left: 0px;
    right: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 99999;
}
.loader:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 9999;
}
.loader img {
    position: relative;
    z-index: 99999;
}

.loaderWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}