.filterWrapper {
    width: 600px;
}
.header .iconClose {
    cursor: pointer;
}
.filterWrapper .header {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #d1d2d3;
}
.header h2 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
}
.filterWrapper .body {
    padding: 20px;
    max-height: 80vh;
    overflow: auto;
}
.filterWrapper .body::-webkit-scrollbar {
    display: none;
}
.filterContainer {
    margin-bottom: 20px;
}
.filterContainer .filterTitle {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.sortWrapper .sortTitle {
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
    line-height: 1.4;
    color: #3f4246;
    border: 1px solid #d1d2d3;
    border-radius: 25px;
    margin-right: 12px;
    margin-bottom: 8px;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    cursor: pointer;
}
.sortWrapper .sort {
    cursor: pointer;
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
    line-height: 1.4;
    background-color: #3674ee;
    color: #fff;
    border: 1px solid #3674ee;
    border-radius: 25px;
    margin-right: 12px;
    margin-bottom: 8px;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.filterContainer .filterTitle .filterIcon {
    display: inline-block;
    margin-right: 8px;
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
    width: 20px;
    height: 20px;
    -o-object-fit: contain;
    object-fit: contain;
}
.btnWrapper {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.btnWrapper button {
    background: #3674ee;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 40px;
    padding: 8px 15px;
    border: 1px solid #3674ee;
    margin-right: 4px;
    margin-left: 4px;
    min-width: 130px;
}
.btnWrapper button.resetFilter {
    background: #f5004a;
    border: 1px solid #f5004a;
}

/* Checkbox CSS Start */
.checkboxContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.boxes {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 8px;
}

.boxes input[type='checkbox'] {
    display: none;
}

.boxes input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: 28px;
    font-size: 12px;
    line-height: 1.4;
    color: #3f4246;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.boxes input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

.boxes input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #3f4246;
    position: absolute;
    left: 0;
    top: 50%;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
    border-radius: 4px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.boxes input[type='checkbox']:checked + label:before {
    width: 10px;
    top: 0px;
    left: 0px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg) translate(0px, -50%);
    -ms-transform: rotate(45deg) translate(0px, -50%);
    transform: rotate(45deg) translate(0px, -50%);
}

@media screen and (max-width: 767px) {
    .filterWrapper {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .btnWrapper button {
        width: 100%;
    }
    .btnWrapper button {
        min-width: auto;
    }
}
