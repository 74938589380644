.amenitiesPagecontainer {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 15px 84px;
}

.amenitiesPagecontainer .innerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.innerContainer .backBtn {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: auto;
  padding: 0;
}

.backBtn span {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #191C21;
}

.innerContainer h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #191C21;
}

.innerContainer h3 span {
  text-transform: capitalize;
}

.innerContainer .amenitiesListing {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.amenitiesListing .amenityBox {
  width: 210px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  cursor: pointer;
}

.amenitiesListing .amenityBox:hover {
  border: 1px solid #3674EE;
}

.amenityBox p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
  text-align: center;
}

.amenityDetailWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.amenityDetailWrapper .amenityMediaWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.amenityDetailWrapper .amenityMediaWrapper .amenityMedia img,
.amenityDetailWrapper .amenityMediaWrapper .amenityMedia video {
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
}


.amenityDetailWrapper .amenityDetail p {
  margin: 0;
  max-width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #191C21;
}

.amenityDetailWrapper .amenityLink {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #191C21;
}

.amenityDetailWrapper .amenityLink:hover {
  color: #3674EE;
}

.amenityLightBoxView {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 102;
  backdrop-filter: blur(16px);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.lightBoxMedia {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 109;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mediaTag {
  max-width: 990px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px;
  position: relative;
}

.lightBoxMedia .mediaTag img,
.lightBoxMedia .mediaTag video {
  max-width: 990px;
  height: auto;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}

.lightBoxMedia .mediaTag .closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 2;
  filter: brightness(0) invert(1);
}

@media screen and (max-width:1440px) {
  .amenityDetailWrapper .amenityMediaWrapper .amenityMedia {
    max-width: calc(33.33% - 20px);
    width: 100%;
  }
}

@media screen and (max-width:1024px) {

  .amenityDetailWrapper .amenityMediaWrapper .amenityMedia {
    max-width: calc(50% - 15px);
    width: 100%;
  }
}

@media screen and (max-width:767px) {

  .amenitiesPagecontainer {
    padding: 24px 16px;
  }

  .amenitiesPagecontainer .innerContainer {
    gap: 16px;
  }

  .amenitiesPagecontainer .innerContainer h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: left;
    text-decoration-skip-ink: none;
  }

  .amenitiesPagecontainer .innerContainer .amenitiesListing {
    gap: 16px;
    width: 100%;
    margin: 8px 0 0 0;
  }

  .amenitiesPagecontainer .innerContainer .amenitiesListing .amenityBox {
    max-width: calc(50% - 8px);
    width: 100%;
    padding: 16px;
    gap: 16px;
  }

  .amenityBox p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

@media screen {}