.textCenter {
    text-align: center;
}

.signupUpperContainer .fornWrapper {
    position: relative;
    padding: 20px 0px;
}

.signupUpperContainer .fornWrapper .propertyImage {
    height: 100%;
}

.textCenter a {
    font-size: 16px;
    line-height: 1.4;
    text-decoration-line: underline;
    text-transform: capitalize;
    font-weight: 500;
}
.container {
    background: #ffffff;
    border-radius: 16px;
    width: 466px;
    margin: auto;
    padding: 45px 40px;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
    position: relative;
    z-index: 9;
    box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07);
    /* / touch-action: none; / */
}
.container.signupContainer {
    width: 835px;
}
.container.rentalTerm {
    width: 580px;
    margin-top: 80px;
    border-radius: 16px !important;
}
.authTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #191c21;
    margin: 0px 0px 16px;
    font-family: 'Rubik', sans-serif;
    text-align: center;
}
.authDesc {
    font-size: 16px;
    line-height: 30px;
    color: #3f4246;
    margin: 0px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
}
.fieldWrapper {
    text-align: left;
    margin-bottom: 24px;
}
.passwordWrapper {
    position: relative;
}
.iconToggle {
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}
.iconToggle img {
    cursor: pointer;
    width: 18px;
}
.fieldWrapper {
    margin-bottom: 16px;
    position: relative;
}
.fieldWrapper label {
    color: #191c21;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 16px;
}
.fieldWrapper input {
    width: 100%;
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    height: 48px;
    padding: 10px 50px 10px 14px;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    font-family: 'Rubik', sans-serif;
    background: #f4f6f9;
}
.invalidCred {
    color: #f5004a;
    display: inline-block;
    font-size: 12px;
    line-height: 1.4;
    position: absolute;
    /* / padding-left: 14px; / */
    width: 100%;
    padding-top: 4px;
}
.fieldWrapper input::-webkit-input-placeholder {
    color: #d1d2d3;
}
.fieldWrapper input::-moz-placeholder {
    color: #d1d2d3;
}
.fieldWrapper input:-ms-input-placeholder {
    color: #d1d2d3;
}
.fieldWrapper input::-ms-input-placeholder {
    color: #d1d2d3;
}
.fieldWrapper input::placeholder {
    color: #d1d2d3;
}
.fieldWrapper input:focus {
    border: 1px solid #51ab86;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.forgetPassword {
    font-size: 14px;
    line-height: 1.4;
    color: #191c21;
    text-decoration: none;
    text-align: left;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
}
.submitWrapper {
    margin-top: 24px;
    margin-bottom: 24px;
}
.submitWrapper button {
    width: 100%;
    background: #51ab86;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    height: 48px;
}
.formWrapper {
    margin-top: 34px;
}
.signinBorder {
    position: relative;
    margin-top: 24px;
    text-align: center;
}
.signinBorder:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background: #b2b3b5;
    left: 0px;
    top: 50%;
}
.signinBorder span {
    font-size: 16px;
    line-height: 28px;
    color: #b2b3b5;
    display: inline-block;
    padding: 0px 16px;
    background-color: #fff;
    position: relative;
}
.socialContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 16px;
    padding: 0px 15px;
}
.socialContainer .iconWrapper {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    height: 48px;
    width: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    cursor: pointer;
}
.socialContainer .iconWrapper:hover img {
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.socialContainer .iconWrapper:hover img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}
.socialContainer .iconWrapper:hover {
    background-color: #e59236;
    border: 1px solid #e59236;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    -webkit-transform: translate(0px, -5px);
    -ms-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
}

/*Checkboxes styles*/
.boxes input[type='checkbox'] {
    display: none;
}

.boxes input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px 'Open Sans', Arial, sans-serif;
    color: #ddd;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.boxes input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

.boxes input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #6cc0e5;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

.boxes input[type='checkbox']:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

a {
    cursor: pointer;
}
.linkWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 24px;
}

.signupContainerMain .faqHeader {
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    font-family: 'Rubik';
    padding: 80px 15px 0;
    text-transform: capitalize;
    text-align: center;
    margin-top: 32px;
}

.signupUpperContainer {
    position: relative;
    padding: 1px 0;
}

.signupUpperContainer .faqContainer {
    margin-top: 30px;
    display: inline-block;
}

.signupUpperContainer .propertyImage {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 650px;
    z-index: 0;
    object-fit: cover;
}

.signupUpperContainer .imgOverlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background-color: #191c21;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
}
.error {
    font-size: 12px;
    line-height: 18px;
    color: #f5004a;
    margin-top: 5px;
    margin-bottom: 5px;
}

.success {
    font-size: 12px;
    line-height: 18px;
    color: #51ab86;
    margin-top: 5px;
    margin-bottom: 5px;
}
.signupUpperContainer .faqContainer {
    margin-bottom: 50px;
}
/* / Responsive Start / */
@media screen and (max-width: 1366px) {
    .container {
        padding: 24px;
    }
    .authTitle {
        font-size: 32px;
        line-height: 39px;
    }
    .formWrapper {
        margin-top: 24px;
    }
    .fieldWrapper label {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 8px;
    }
    .fieldWrapper input {
        font-size: 14px;
        line-height: 28px;
    }
    .submitWrapper {
        margin-top: 24px;
    }
    .submitWrapper button {
        font-size: 16px;
        line-height: 28px;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        margin: auto;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        padding: 48px;
        width: 100%;
        max-width: 600px;
    }
    .authTitle {
        font-size: 40px;
        line-height: 48px;
    }
    .formWrapper {
        margin-top: 34px;
    }
    .fieldWrapper label {
        font-size: 16px;
        line-height: 28px;
    }
    .container.signupContainer {
        width: 100%;
        max-width: 90%;
    }
    .signupContainerMain > div {
        width: 100%;
        /* / max-width: 600px !important; / */
    }
    .container.rentalTerm {
        width: 100%;
        max-width: 90%;
    }
    .signupUpperContainer .faqContainer {
        margin-top: 12px;
        padding: 0 15px;
    }
    .forgotPasswordContainer,
    .loginContainer {
        margin: auto;
    }
}
@media screen and (max-width: 767px) {
    .fornWrapper .container {
        background-color: white;
        padding: 15px;
        max-width: 90% !important;
    }
    .container {
        /* background-color: transparent; */
        margin: auto;
    }
    .authTitle {
        font-size: 24px;
        line-height: 29px;
    }
    .authDesc {
        font-size: 14px;
        line-height: 1.4;
    }
    .formWrapper {
        margin-top: 24px;
    }
    .fieldWrapper label {
        font-size: 14px;
        line-height: 28px;
    }

    .container {
        width: 100%;
        max-width: 500px;
        padding: 0px;
        box-shadow: none;
        padding: 0 15px;
    }
    .container.rentalTerm {
        width: 100%;
        border: none;
        max-width: none;
    }
    .forgotPasswordContainer,
    .loginContainer {
        margin: auto;
    }
}

@media screen and (max-width: 414px) {
    .container.signupContainer {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .fornWrapper .container {
        max-width: 94% !important;
    }
    .signupUpperContainer .faqContainer {
        margin-bottom: 40px;
    }
}
