.appointmentCard {
  max-width: 343px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.appointmentCard .upperContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
}

.upperContainer .iconWrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
}

.appointmentCard .upperContainer:nth-child(1) .title {
  padding-right: 48px;
}


.appointmentCard .upperContainer .inlineItems {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.appointmentCard .upperContainer .inlineItems .detailWrapper {
  min-width: 120px;
  max-width: 50%;
  width: 100%;
}

.appointmentCard .upperContainer .detailWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.appointmentCard .upperContainer .detailWrapper .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #191C21;
}

.appointmentCard .upperContainer .detailWrapper .info {
  font-size: 14px;
  font-weight: 300;
  line-height: 16.8px;
  text-align: left;
  color: #3F4246;
  width: max-content;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.appointmentCard .middleContainer {
  border: 1px solid;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.openViaContainer {
  width: auto;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.amazonDoorKeyWrapper {
  color: black;
  width: auto;
  display: inline-flex;
  min-height: 40px;
  padding: 0 12px 0 0;
  border: 1px solid black;
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 8px;
}

.KeyIconWrapper {
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openViaContainer.disabled {
  cursor: not-allowed;
}

.openViaContainer span {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #3674EE;
}

.openViaContainer.disabled span {
  color: #D1D2D3;
}

.appointmentCard .actionBtn {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.appointmentCard .btnText {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000;
}

.appointmentCard .lowerContainer {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  z-index: 5;
  width: 100%;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.appointmentCard .lowerContainer.show {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

.appointmentCard .lowerContainer .infoBox {
  max-width: calc(40% - 12px);
  width: 100%;
  display: flex;
  gap: 12px;
}

.appointmentCard .lowerContainer .infoBox:last-child {
  max-width: calc(60% - 12px);
}

.appointmentCard .lowerContainer .infoBox:nth-child(2) .leftContainer {
  background-color: #3674EE;
}

.lowerContainer .infoBox .leftContainer {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 20px;
  background-color: #191C21;
  display: flex;
  align-items: center;
  justify-content: center;
}


.lowerContainer .infoBox .rightContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.lowerContainer .infoBox .rightContainer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000;
}

.lowerContainer .infoBox .rightContainer p:nth-child(2) {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #191C21;
  text-decoration: underline;
  cursor: pointer;
}

.appointmentCard .lowerContainer .infoBox:nth-child(2) .rightContainer p:nth-child(2) {
  text-decoration: none;
  cursor: pointer;
  color: #3674EE;

}

@media screen and (max-width:767px) and (min-width:720px) {
  .appointmentCard {
    max-width: calc(50% - 8px);
  }
}

@media screen and (max-width:719px) {
  .appointmentCard {
    max-width: 100%;
  }
}

@media screen and (max-width:440px) {

  .appointmentCard {
    padding: 12px;
  }

  .appointmentCard .upperContainer .inlineItems {
    gap: 16px;
  }

  .appointmentCard .upperContainer .detailWrapper .info {
    font-size: 13px;
  }

  .appointmentCard .lowerContainer {
    gap: 16px;
  }

  .appointmentCard .lowerContainer .infoBox {
    max-width: calc(40% - 8px);
  }

  .appointmentCard .lowerContainer .infoBox:last-child {
    max-width: calc(60% - 8px);
  }

  .appointmentCard .lowerContainer .infoBox {
    gap: 8px;
  }

  .appointmentCard .lowerContainer .infoBox .leftContainer {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  .appointmentCard .lowerContainer .infoBox .leftContainer svg {
    height: 16px;
    width: 16px;
  }

  .appointmentCard .lowerContainer .infoBox .rightContainer p {
    font-size: 13px;
  }
}

@media screen and (max-width:375px) {
  .appointmentCard .lowerContainer .infoBox .leftContainer {
    width: 28px;
    height: 28px;
    min-width: 28px;
  }

  .appointmentCard .lowerContainer .infoBox .leftContainer svg {
    height: 14px;
    width: 14px;
  }
}