.appointmentFooter ul,
.tableFooter {
    display: -webkit-box;
    display: -ms-flexbox;
}

.appointmentsTabs .active,
.appointmentsTabs .tab {
    border-radius: 8px;
    padding: 6px 20px;
    min-width: 125px;
    font-weight: 500;
    height: 44px;
    transition: all ease 0.3s;
}

.appointmentsTabs .tab {
    color: #3f4246;
}

.appointmentsTabs .active {
    color: #fff;
}

.container {
    max-width: 1440px;
    padding: 60px 15px 50px;
    margin-left: auto;
    margin-right: auto;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 16px;
}

.existingApointments {
    display: none;
}

.appointmentTitle {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.appointmentTitle h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 60px;
    color: #191c21;
}

.appointmentTitle .btnGreen {
    display: inline-flex;
    gap: 10px;
    border-radius: 8px;
    background: #ffb800;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
    border: none;
    text-decoration: none;
}

.appointments {
    width: 100%;
    margin: auto;
    overflow: auto;
    height: calc(100vh - 282px);
}

.appointments::-webkit-scrollbar {
    display: none;
}

.appointmentTable {
    width: 100%;
}

.appointmentsTabs {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
}

.appointmentTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.appointmentTable tr th {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #8C8D90;
    text-transform: capitalize;
    padding: 12px 15px;
}

.appointmentTable tr td,
.appointmentTable tr th {
    vertical-align: middle;
}

.appointmentTable tr th img {
    width: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    display: inline-block;
    margin-left: 8px;
    cursor: pointer;
}

.appointmentTable tr th span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.appointmentTable tr td {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    padding: 23px 12px;
    color: #3F4246;
    border-bottom: 1px solid #EFEFEF;
}

.appointmentTable tr td .actionButtonWrapper {
    position: relative;
    display: flex;
    justify-content: end;
}

.appointmentTable tr td .actionButtonWrapper .MageDotsIcon {
    background-color: transparent;
    border: 1px solid transparent;
    width: 28px;
    height: 28px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    z-index: 8;
    position: relative;
}

.dropDownBackDrop {
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
}

.appointmentTable tr td .actionButtonWrapper .actionContainer {
    position: absolute;
    top: 40px;
    right: 12px;
    display: none;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    width: 219px;
    background-color: #fff;
    box-shadow: 2px 4px 24px 0px #0000000F;
    border-radius: 8px;
    padding: 16px 8px;
    gap: 4px;
    z-index: 99;
}

.appointmentTable tr td .actionButtonWrapper .actionContainer.top {
    bottom: 40px;
    top: auto;
}

.appointmentTable tr td .actionButtonWrapper .actionContainer.active {
    display: flex;
}

.actionContainer .actionBox {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
    padding: 8px;
}

.actionContainer .actionBox:hover {
    background-color: #F6F6F6;
    border-radius: 4px;
}

.actionBox span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #8C8D90;
}

.appointmentTable tr td .openViaContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}

.appointmentTable tr td .openViaContainer.disabled {
    color: #8C8D90;
    cursor: not-allowed;
}

.appointmentTable tbody tr td:first-child {
    cursor: pointer;
}


.actionBtns {
    display: flex;
    gap: 16px;
}

.tableHeader {
    position: relative;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #F6F6F6;
}

.appointmentFooter,
.appointmentTable tr:first-child,
.appointmentTable tr:first-child:hover {
    background-color: #f5f9fe;
    z-index: 9;
}

.appointmentTable tr td .btnGreen {
    display: inline-flex;
    gap: 10px;
    border-radius: 8px;
    background: #51AB86;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
    border: none;
}

.appointmentTable tr td .btnRed {
    color: #ffffff;
    background-color: rgba(245, 0, 74, 0.65);
    display: inline-flex;
    gap: 10px;
    border-radius: 8px;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    cursor: not-allowed;
}

.appointmentTable tr td .btnGreen.disabled {
    cursor: not-allowed;
    background-color: #a9a9a9;
    color: #191c21;
    opacity: 0.4;
}

.appointmentTable tr,
.appointmentTable tr .actionContainer .iconDelete,
.appointmentTable tr .actionContainer .iconEdit,
.appointmentTable tr .actionContainer .iconMap {
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    margin: auto;
}

.appointmentTable tr td .actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.appointmentTable tr.modifiedAppointment {
    background-color: #3674ee;
}

.appointmentTable tr.modifiedAppointment>td {
    color: #fff;
}

.appointmentTable tr.modifiedAppointment>td img {
    filter: brightness(0) invert(1);
}

.Ul {
    text-decoration: underline;
}

.iconDelete {
    width: 100%;
    max-width: 24px;
    cursor: pointer;
    filter: none;
    transition: all ease 0.3s;
}

.iconEdit {
    width: 100%;
    max-width: 20px;
    cursor: pointer;
    filter: none;
    fill: #d1d2d3;
    transition: fill 0.3s ease-in-out;
}

.iconMap:hover {
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    transition: all ease 0.3s;
}

.iconDelete:hover {
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
    transition: all ease 0.3s;
}

.iconEdit:hover {
    filter: invert(71%) sepia(83%) saturate(5475%) hue-rotate(162deg) brightness(95%) contrast(101%);
    transition: all ease 0.3s;
}

.tableFooter {
    display: flex;
}

.appointmentFooter {
    position: sticky;
    bottom: 0;
    padding: 15px 0;
}

.appointmentFooter ul {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.appointmentFooter ul li,
.appointmentsTabs .tab {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    -webkit-box-align: center;
    cursor: pointer;
}

.appointmentFooter ul li {
    height: 34px;
    width: 34px;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #8c8d90;
}

.appointmentFooter ul li:first-child,
.appointmentFooter ul li:last-child {
    width: auto;
    margin: 0 8px;
}

.appointmentFooter ul li.active {
    background: #ffb800;
    color: #fff;
    border-radius: 50%;
}

.appointmentsTabs .tab {
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.appointmentsTabs .active {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #51ab86;
    cursor: pointer;
}

.modalBodyContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 5px 10px 32px 0px #277FE512;
    width: 688px;
}

.modalBodyContainer .modalHeader {
    padding: 24px 24px 16px;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.modalBodyContainer .modalHeader h3 {
    font-family: Rubik;
    font-size: 32px;
    font-weight: 700;
    line-height: 44.8px;
    text-align: left;
}


.modalHeader .closeIcon {
    filter: brightness(1) invert(1);
    cursor: pointer;
}

.modalBodyContainer .modalBodyInnerContainer {
    /* padding: 0 24px; */
    width: calc(100% - 48px);
    margin: 0 auto;
    max-height: 480px;
    height: 100%;
    position: relative;
}

.modalBodyContainer .camLayer {
    position: absolute;
    top: 0px;
    bottom: 0;
    height: 481px;
    width: 640px;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0.7;
}

.camLayer img {
    object-fit: cover;
    height: 481px;
}

.modalBodyContainer .webCamContainer {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage img {
    height: 100%;
    width: 100%;
}

.modalBodyContainer .modalFooter {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.modalBodyContainer .modalFooter .captureBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .submitBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .retakeBtn {
    background-color: #fff;
    border: 1px solid #51ab86;
    color: #51ab86;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.accessModalBodyContainer {
    background-color: #fff;
    width: 732px;
    max-width: 100%;
    padding: 24px;
    box-shadow: 5px 10px 32px 0px #277FE512;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: relative;
}

.accessModalBodyContainer .closeIconContainer {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.accessModalBodyContainer .successMsg {
    font-family: Rubik;
    font-size: 32px;
    font-weight: 700;
    line-height: 44.8px;
    text-align: center;
    color: #191c21;
    max-width: 537px;
}

.accessModalBodyContainer .code {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 500;
    line-height: 33.6px;
    text-align: center;
    color: #191c21;
}

.checkMark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.checkmarkCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmarkCheck {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.appointmentCardListing {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.noAppointmentContainer {
    height: calc(100vh - 282px);
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
}

.noAppointmentContainer .noAppointments {
    white-space: pre-wrap;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #191c21;
}

.noAppointmentContainer .btnGreen {
    display: inline-flex;
    gap: 10px;
    border-radius: 8px;
    background: #ffb800;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
    border: none;
    text-decoration: none;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #51AB86;
    }
}

@media screen and (max-width: 1366px) {
    .appointmentTitle h1 {
        font-size: 32px;
        line-height: 1.2;
    }

    .appointmentTable tr td,
    .appointmentTable tr th {
        font-size: 16px;
        line-height: 1.2;
    }

    .appointments {
        height: calc(100vh - 280px);
    }

    /* .container {
        max-width: 90%;
    } */
}

@media screen and (max-width: 1024px) {
    .container {
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .appointments {
        height: calc(100vh - 240px);
    }

    .appointmentTitle h1 {
        font-size: 24px;
        line-height: 1.2;
    }

    .container {
        padding-bottom: 20px;
    }

    .Appointments_appointments__SV2pI {
        height: calc(100vh - 270px);
    }

    .appointmentTable tr td .btnGreen {
        word-break: break-word;
        padding: 2px 4px;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .noAppointmentContainer .noAppointments {
        font-size: 16px;
        line-height: 19px;
        white-space: normal;
    }

    .noAppointmentContainer .noAppointmentImg {
        height: 195px;
        width: 99px;
    }
}

@media screen and (max-width: 767px) {
    .container {
        height: calc(100vh - 72.3px);
        padding: 30px 15px;
    }

    .existingApointments {
        display: flex;
        text-align: left;
        font-size: 16px;
        line-height: 19.2px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    .appointmentTable tr td,
    .appointmentTable tr th {
        font-size: 12px;
        line-height: 1.2;
        height: 50px;
        padding: 8px 15px;
        min-width: 120px;
    }

    .appointments {
        height: calc(100vh - 210px);
    }

    .appointmentTable tr td:first-child,
    .appointmentTable tr th:first-child {
        padding-left: 15px;
    }

    .appointmentTable tr td:first-child,
    .appointmentTable tr td:last-child,
    .appointmentTable tr th:first-child,
    .appointmentTable tr th:last-child {
        min-width: auto;
    }

    .modalBodyContainer {
        width: calc(100vw - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer {
        height: auto;
        max-height: none;
        max-width: calc(100% - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer {
        width: 100%;
        left: 0;
        right: 0;
        height: calc(100% + 1px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer img {
        height: 100%;
    }

    .modalBodyContainer .modalBodyInnerContainer .capturedImage,
    .modalBodyContainer .modalBodyInnerContainer .webCamContainer {
        height: 100%;
        width: 100%;
    }

    .accessModalBodyContainer {
        transform: scale(0.7);
    }
}

@media screen and (max-width: 580px) {
    .appointmentTitle {
        /* flex-direction: column; */
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .appointmentsTabs {
        width: 100%;
        margin-top: 20px;
    }

    .appointmentsTabs .active,
    .appointmentsTabs .tab {
        width: 50%;
    }

    .container {
        padding: 24px 16px 16px !important;
    }

    .appointmentsTabs .active,
    .appointmentsTabs .tab {
        height: 40px;
        font-size: 14px;
    }

    .modalBodyContainer .modalHeader {
        padding: 16px;
    }

    .modalBodyContainer .modalHeader h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }

    .modalBodyContainer .modalFooter {
        gap: 10px;
        padding: 16px;
        flex-direction: column-reverse;
    }

    .modalBodyContainer .modalFooter .retakeBtn,
    .modalBodyContainer .modalFooter .submitBtn {
        width: 100%;
    }

    .accessModalBodyContainer {
        transform: scale(0.5);
    }

    .appointmentTable tr td .btnGreen {
        width: max-content;
        min-width: 220px;
        display: inline-flex;
        padding: 4px 8px;
    }

    .appointmentTable tr td:last-child>div {
        width: max-content;
    }
}

@media screen and (max-width: 414px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .appointments {
        height: calc(100vh - 240px);
    }

    /* .appointmentTitle {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    } */

    .accessModalBodyContainer {
        transform: scale(0.4);
    }
}

.noData {
    text-align: center;
}

@media screen and (max-width:520px) {
    .modalBodyContainer .modalHeader h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }

    .modalBodyContainer .modalFooter {
        flex-direction: column-reverse;
    }

    .modalBodyContainer .modalFooter .retakeBtn,
    .modalBodyContainer .modalFooter .submitBtn {
        width: 100%;
    }

    .appointmentTitle h1 {
        font-size: 20px;
    }

    .appointmentTitle .btnGreen {
        padding: 6px 10px;
        font-size: 14px;
        line-height: 19.2px;
        height: 32px;
    }
}

.swipeModal {
    position: fixed;
    z-index: 999;
    background-color: #fff;
    max-width: 341px;
    width: 100%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
}

.swipeModal.inactive {
    bottom: -100%;
}

.swipeModal p {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    color: #191c21;
}

.backdrop {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    filter: blur(5px);
}

.swipeModal .btn {
    padding: 16px;
    background-color: transparent;
    border: none;
    border-top: 1px solid #d1d2d3;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    cursor: pointer;
    color: #3674ee;
}

.swipeModal .btn.delete {
    color: #F5004A;
}

.swipeModal .btn.cancel {
    background: #f4f4f4;
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    border-radius: 14px;
    border: none;
}

@media screen and (max-width:991px) and (min-width:768px) {
    .appointments .appointmentTable tr td:nth-child(3) {
        white-space: pre-wrap;
    }
}