.page-loader {
  position: fixed;
  min-height: 100vh;
  top: 0px;
  width: 100vw;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
}