.header {
    text-align: center;
    margin-bottom: 32px;
}

.header .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 16px;
    color: #000000;
}

.header .description {
    font-size: 16px;
    line-height: 30px;
    color: #3f4246;
    max-width: 535px;
    margin: 0 auto;
}

.listItems {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.listItems .item {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 12px 0px;
    position: relative;
    cursor: pointer;
}

.listItems .item.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.listItems .item.item.disabled .itemDesc span {
    /* / opacity: 0.5; / */
    background-color: #ffffff;
    color: #3f4246;
}

/* .listItems .item:before {
    content: '';
    position: absolute;
    background-image: url('../../../../images/icons/icon-chevron-right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 9px;
    height: 14px;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    right: 0px;
} */
/* .listItems .item:last-child {
    border-bottom: 0px;
} */
.listItems .item .num {
    min-width: 46px;
    min-height: 46px;
    max-width: 46px;
    max-height: 46px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #d1d2d3;
    font-size: 14px;
    line-height: 28px;
    color: #3f4246;
}

.listItems .item .num img {
    transform: scale(0);
}

.listItems .item .num img.verificationImage {
    transition: all 0.5s;
    transform: scale(1);
}

.listItems .item .itemDesc {
    margin-left: 16px;
    max-width: 335px;
    width: 100%;
}

.listItems .item .itemDesc .errorText {
    margin-bottom: 0px;
    position: absolute;
    font-size: 14px;
    line-height: 1.4;
    color: #f5004a;
    padding-left: 14px;
    margin-top: 5px;
    display: block;
    border: none;
    border-radius: none;
    background-color: transparent;
    padding: 0;
    bottom: 0;
}

.listItems .item .itemDesc span {
    display: block;
    /* / outline: 1px solid green; / */
    border: 1px solid #51ab86;
    border-radius: 8px;
    background-color: #51ab86;
    padding: 8px 10px;
}

.listItems .item .itemDesc .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    /* / color: #191c21; / */
    margin-bottom: 8px;
    color: #ffffff;
}

/* .listItems .item .itemDesc .description {
    font-size: 14px;
    line-height: 26px;
    color: #3f4246;
} */
/* .onBoardFooter {
    margin-top: 0px;
    text-align: center;
}
.onBoardFooter button {
    height: 48px;
    width: 100%;
    max-width: 375px;
    border-radius: 8px;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}
.onBoardFooter button.btnDisable {
    background: #d1d2d3;
    color: #8c8d90;
    cursor: not-allowed;
}
.onBoardFooter button.btnSubmit {
    background: #51ab86;
    color: #fff;
}
.onBoardFooter .signInLink {
    margin-top: 16px;
}
.onBoardFooter .signInLink button {
    font-size: 16px;
    line-height: 1.2;
    text-decoration-line: underline;
    text-transform: capitalize;
    max-width: max-content;
    color: #3f4246;
} */

/* Checkboxes styles*/
.boxes {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 240px;
    margin: 0 auto;
    cursor: pointer;
}

.boxes input[type='checkbox'] {
    all: revert;
    position: absolute;
    z-index: 99;
    opacity: 0;
    left: 0px;
}

.boxes input[type='checkbox']+label {
    display: block;
    margin-left: 32px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.boxes input[type='checkbox']+label:last-child {
    margin-bottom: 0;
}

.boxes input[type='checkbox']+label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #e59236;
    position: absolute;
    left: 0px;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

.boxes input[type='checkbox']:checked+label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* .errorMsg {
    color: #ff0000;
    font-size: 15px;
    line-height: 1.2;
} */
.signBefore {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.signBefore div {
    font-size: 18px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #51ab86;
    cursor: pointer;
    font-weight: 600;
}

.showRentalTerm {
    display: none;
}

.showRentalTerm.active {
    display: block;
}

.onBoardBody {
    display: flex;
    justify-content: center;
}

/* / Responsive Start / */
@media screen and (max-width: 1024px) {
    .listItems .item {
        width: 100%;
        padding-bottom: 0px;
    }

    .header {
        margin-bottom: 16px;
    }

    .listItems .item .itemDesc .title {
        font-size: 16px;
        line-height: 1.2;
    }

    .boxes {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        max-width: 100%;
    }

    .listItems .item .itemDesc .title {
        margin-bottom: 0px;
        margin-top: 6px;
    }

    /* .onBoardFooter button {
        width: 100%;
        max-width: 100%;
    } */
}

@media screen and (max-width: 767px) {
    .header .title {
        font-size: 24px;
        line-height: 29px;
    }

    .listItems .item {
        display: flex;
        align-items: center;
    }

    .listItems .item .itemDesc .title {
        margin-top: 0px;
    }

    /* .listItems .item .itemDesc .description {
        line-height: 1.4;
    } */

    .modalBodyContainer {
        width: calc(100vw - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer {
        height: auto;
        max-height: none;
        max-width: calc(100% - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer {
        width: 100%;
        left: 0;
        right: 0;
        height: calc(100% + 1px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer img {
        height: 100%;
    }

    .modalBodyContainer .modalBodyInnerContainer .capturedImage,
    .modalBodyContainer .modalBodyInnerContainer .webCamContainer {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .signBefore {
        text-align: center;
    }

    /* .onBoardFooter button {
        height: 40px;
        font-size: 16px;
        line-height: 1.2;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    } */
}



@media screen and (max-width:520px) {
    .modalBodyContainer .modalHeader h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }

    .modalBodyContainer .modalFooter {
        flex-direction: column-reverse;
    }

    .modalBodyContainer .modalFooter .retakeBtn,
    .modalBodyContainer .modalFooter .submitBtn {
        width: 100%;
    }
}