.toastContainer {
    background: #ffffff;
    box-shadow: 5px 10px 32px rgb(65 65 65 / 9%);
    border-radius: 8px;
    width: 100%;
    max-width: 438px;
    display: flex;
    padding: 20px 16px;
    align-items: center;
    position: fixed;
    right: 0px;
    top: 100px;
    animation-direction: alternate-reverse;
    animation-name: run;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    z-index: 99999999;
}

@keyframes run {
    0% {
        transform: translate(100%, 0px);
    }

    10% {
        opacity: 1;
    }

    40% {
        transform: translate(-10px, 0px);
    }

    80% {
        opacity: 1;
        transform: translate(-10px, 0px);
    }

    100% {
        transform: translate(100%, 0px);
        opacity: 0;
    }
}

/* .toastContainer.show {
	transform: translate(-10px, 0px);
	transition: all ease-in-out 2s;
} */
.toastContainer .toastSuccess,
.toastContainer .toastError,
.toastContainer .toastWarning {
    position: relative;
    margin-right: 16px;
    padding-left: 16px;
}

.toastContainer .toastSuccess:before,
.toastContainer .toastError:before,
.toastContainer .toastWarning:before {
    content: '';
    position: absolute;
    box-shadow: 5px 10px 32px rgb(65 65 65 / 9%);
    border-radius: 8px;
    width: 4px;
    height: 58px;
    left: 0px;
    top: 50%;
    transform: translate(0px, -50%);
}

.toastContainer .toastSuccess:before {
    background: #51ab86;
}

.toastContainer .toastWarning:before {
    background: #ffb800;
}

.toastContainer .toastError:before {
    background: #f5004a;
}

.toastContainer .toastTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    color: #191c21;
    margin: 0px;
}

.toastContainer .toastInfo {
    font-size: 14px;
    line-height: 26px;
    text-transform: capitalize;
    color: #b2b3b5;
    margin: 0px;
}

.toastClose img {
    position: absolute;
    cursor: pointer;
    max-width: 15px;
    top: 12px;
    right: 16px;
}


@media screen and (max-width:520px) {
    .toastContainer {
        max-width: calc(100vw - 32px);
        width: 100%;
    }
}