.calendarWrapper {
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 16px;
}
.btnWrapper {
    text-align: center;
    margin-top: 30px;
}
.btnWrapper button {
    background: #51ab86;
    border-radius: 8px;
    padding: 10px 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    height: 48px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}
.btnDisable.btnWrapper button {
    background-color: #d1d2d3;
    color: #000;
    cursor: not-allowed;
    pointer-events: none;
}
.slotsWrapper .timeslotWrapper {
    margin: 0px 24px;
}
.calendarWrapper.timeslotContainer {
    padding-top: 116px;
}
.timeslotContainer .timeslotNote {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
    color: #191c21;
    text-align: center;
}
.timeslotWrapper .timeslotNote span {
    color: #3674ee;
    text-decoration: underline;
    cursor: pointer;
}
.slotSelectionWrapper {
    margin-top: 64px;
    margin-bottom: 116px;
}
.slotSelectionWrapper .slotTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 32px;
}
.selectTime {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    display: inline-block;
    padding: 0px 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 48px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.selectTimeOuter {
    position: relative;
}

.selectTimeOuter .bookedSlot {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    border-radius: 8px;
    border: 0;
    background-color: #d1d2d3;
    padding: 12px 18px;
    cursor: not-allowed;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
}
.selectTimeOuter .bookedSlotsSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-y: auto;
    position: relative;
    gap: 16px;
}
.slots span {
    display: block;
    cursor: pointer;
}
.slots.show {
    display: inline-block;
}
.slots.timeOptions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 200px;
}
.slots.timeOptions span {
    width: 33.33%;
}
.slots.hide {
    display: none;
}
.hoursWrapper .slots {
    position: absolute;
    left: 0px;
    margin-top: 30px;
    padding: 8px;
    background-color: #fff;
    border-radius: 8px;
    top: 0px;
    border: 1px solid #d1d2d3;
}
.iconClock {
    width: 24px;
    margin-right: 10px;
    display: inline-block;
}
.hoursWrapper {
    position: relative;
    margin: 0px 2px;
}
.startTimeTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 12px;
    text-align: left;
}
.slotsWrapper {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.slotsEndTime {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin: 0px 24px;
}
.slotsEndTime .slotDisable {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    padding: 0px 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 48px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 127.52px;
    background: #d1d2d3;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    cursor: not-allowed;
}
.errorMsg {
    text-align: center;
    color: #f5004a;
    font-size: 14px;
    line-height: 1.2;
}
.calendarOuter {
    display: flex;
}
.calendarContent,
.selectTimeOuter {
    flex: 0 0 50%;
}
.selectTimeOuter > h2 > span {
    color: #3674ee;
}
.calendarContent {
    padding-right: 32px;
}
.calendarContent > div > div > div:last-child > div > div > div > div:first-child {
    position: relative;
}
.calendarContent > div > div > div:last-child > div > div > div > div:first-child::after {
    position: absolute;
    content: '';
    width: 97%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: #d1d2d3;
}
.selectTimeWrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
}
.selectTimeOuter {
    position: relative;
}
.selectTimeOuter::after {
    position: absolute;
    content: '';
    top: -3px;
    left: -32px;
    width: 1px;
    height: 98%;
    background: #d1d2d3;
}

.selectTimeWrapper .selectTime {
    padding: 12px 18px;
    cursor: pointer;
}
.selectTimeOuter h2 {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #191c21;
    margin-top: 13px;
}
.selectTimeOuter > p {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 16px;
    color: #191c21;
    margin-top: 24px;
}
.selectTimeWrapper {
    margin-top: 24px;
    margin-bottom: 5px;
    max-height: 190px;
    height: 100%;
    overflow-y: auto;
    /* overflow: hidden; */
}
.selectTimeWrapper .selectTime {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    border-radius: 8px;
    border: 0;
}
.selectTimeWrapper .selectTime:focus,
.selectTimeWrapper .selectTime.active {
    background: #3674ee;
    color: white;
}
.selectTimeWrapper > p.officeHours {
    background: #ebfff7;
}

.slotSelected {
    background-color: #3674ee;
    color: #fff;
    cursor: pointer;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-radius: 8px;
    border: 0;
    display: inline-flex;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.officeOpenNotice {
    margin: 24px 0px;
    position: relative;
    margin-left: 26px;
}
.officeOpenNotice::before {
    position: absolute;
    content: '';
    left: -26px;
    top: 5px;
    width: 18px;
    height: 18px;
    background: #ebfff7;
}

.scheduleAppointmentWrapper {
    position: absolute;
    bottom: 12px;
    width: 100%;
}

.scheduleAppointmentWrapper > button {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    background: #51ab86;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.activeOfficeTime {
    background-color: #fff9e4 !important;
}
.leasingOfficeBoxWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: #3f4246;
    font-family: 'Rubik';
    font-size: 14px;
    line-height: 17px;
}
.leasingOfficeBox {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #fff9e4;
    margin-right: 8px;
}
.noRecordsWrapper {
    display: flex;
    justify-content: center;
    height: 300px;
}
.noRecordsContent {
    width: 100%;
    text-align: center;
    padding-top: 100px;
}
/* Responsive Start */
@media screen and (max-width: 1400px) {
    .selectTimeWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    .selectTimeOuter .bookedSlot {
        font-size: 14px;
        line-height: 1.4;
        padding: 12px 8px;
    }
}
@media screen and (max-width: 1222px) {
    .selectTimeWrapper {
        gap: 14px;
    }
    .selectTimeWrapper .selectTime {
        padding: 1px 12px;
    }
    .selectTimeWrapper {
        grid-template-columns: repeat(2, 1fr);
        max-height: 152px;
    }

    .selectTimeOuter .bookedSlotsSection {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 1024px) {
    .selectTimeOuter .bookedSlotsSection {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .scheduleAppointmentWrapper {
        position: relative;
    }

    .selectTimeOuter h2.appointment {
        margin: 0;
    }

    .selectTimeWrapper.minTimeWrapper {
        max-height: 102px;
    }
}

@media screen and (max-width: 865px) {
    .selectTimeWrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 767px) {
    .calendarWrapper {
        padding: 15px;
    }
    .selectTimeOuter .bookedSlotsSection {
        grid-template-columns: repeat(1, 1fr);
    }
    .calendarOuter {
        display: block;
    }
    .calendarContent,
    .selectTimeOuter {
        flex: 0 0 100%;
        padding: 0;
    }

    .selectTimeOuter h2 {
        margin-top: 16px;
    }

    .selectTimeOuter::after {
        display: none;
    }
    .selectTimeOuter h2 {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        margin-top: 30px;
    }
    .selectTimeOuter > p {
        font-weight: 500;
        font-size: 15px;
        margin-top: 15px;
    }
    .selectTimeWrapper .selectTime {
        font-size: 15px;
        line-height: 19px;
        /* flex: 1; */
    }
    .selectTimeWrapper {
        max-height: 190px;
        height: 100%;
        overflow-y: auto;
    }

    .selectTimeWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 767px) {
    .calendarWrapper {
        height: calc(100dvh - 130px);
        overflow-y: auto;
    }

    .calendarWrapper::-webkit-scrollbar-track {
        border-radius: 0 0 15px 0;
    }
}
@media screen and (max-width: 414px) {
    .btnWrapper button {
        height: 40px;
    }
    .selectTimeWrapper .selectTime {
        flex: 1;
    }
    /* .scheduleAppointmentWrapper {
        margin-bottom: 160px;
    } */
    .selectTimeWrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}
