.container {
    width: 591px;
}
.header {
    padding: 26px 24px;
    border-bottom: 1px solid #d1d2d3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.header .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}
.header .iconClose {
    width: 100%;
    max-width: 32px;
    cursor: pointer;
}
.body {
    padding: 24px;
}
.textCenter {
    text-align: center;
}
.body .bodyTitle {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-transform: capitalize;
    margin-bottom: 24px;
}
.body .deleteDetails {
    font-size: 16px;
    line-height: 28px;
    color: #b2b3b5;
    margin: 0px;
}
.btnWrapper {
    margin-top: 24px;
}
.btnWrapper .btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    border: 1px solid transparent;
    border-radius: 8px;
    height: 40px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 6px 21px;
    margin: 0px 12px;
    cursor: pointer;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.btnWrapper .btn:hover {
    -webkit-box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.13);
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.13);
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.btnWrapper .btn.cancel {
    border: 1px solid #191c21;
}
.btnWrapper .btn.delete {
    background: #f5004a;
    border: 1px solid #f5004a;
    color: #fff;
}

/* Responsive Start */
@media screen and (max-width: 767px) {
    .container {
        width: 100%;
        max-width: 591px;
    }
    .header .title {
        font-size: 24px;
        line-height: 1.2;
    }
}
@media screen and (max-width: 575px) {
    .body .deleteDetails br {
        display: none;
    }
}
@media screen and (max-width: 414px) {
    .header .title {
        font-size: 20px;
        line-height: 1.2;
    }
    .header,
    .body {
        padding: 15px;
    }
    .body .bodyTitle {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 16px;
    }
    .body .deleteDetails {
        font-size: 14px;
        line-height: 1.4;
    }
    .btnWrapper .btn {
        font-size: 14px;
        line-height: 1.2;
        margin: 0px 4px;
    }
}
