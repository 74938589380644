.wrapper {
    width: 100%;
    max-width: 939px;
    /* box-shadow: 5px 10px 32px rgba(39, 127, 229, 0.07); */
    border-radius: 24px;
    background-color: #fff;
    margin: auto;
    /* padding: 48px; */
    /* transform: scale(0.9); */
}

.header {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: #191c21;
    position: relative;
    margin-bottom: 57px;
}

.header::after {
    content: '';
    width: 111%;
    border-bottom: solid 1px #d1d2d3;
    position: absolute;
    z-index: 1;
    top: 163%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .header .back {
    min-width: 48px;
    min-height: 48px;
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
}
.header .back .icon {
    transform: scale(-1);
} */
.header h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    font-family: 'Rubik';
    margin-top: 20px;
}

.modalBodyContainer .modalHeader h3.modalH3 {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modalBodyInnerContainer .preWrappedMessage {
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.guestForm {
    display: flex;
    flex-wrap: wrap;
    margin: 25px -12px 0px;
}

.guestForm .fieldWrapper {
    margin-bottom: 32px;
}

.guestForm .fieldWrapper.sm {
    width: 50%;
    padding: 0px 12px;
}

.guestForm .fieldWrapper.lg {
    width: 50%;
    padding: 0px 12px;
}

.guestForm .fieldWrapper.md {
    width: 50%;
    padding: 0px 12px;
}

.guestForm .fieldWrapper label {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    margin-bottom: 16px;
}

.guestForm .fieldWrapper input,
.guestForm .fieldWrapper select {
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 10px 10px 10px 16px;
    background: #f4f6f9;
}

.guestForm .fieldWrapper.password input {
    padding-right: 35px;
}

.guestForm .pwdWrapper {
    position: relative;
}

.btn {
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: inline-block;
    cursor: pointer;
}

.submit {
    background: #51ab86;
    color: #ffffff;
}

.btnWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 12px;
}

.disabled {
    background: #d1d2d3;
    color: #8c8d90;
    pointer-events: none;
}

/* .submit button {
    height: 48px;
    width: 100%;
    max-width: 375px;
    background: #51ab86;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    cursor: pointer;
} */

.hidden {
    pointer-events: none;
    opacity: 0.7;
}

.dropDownOption,
.ipWrapper {
    position: relative;
}

.ipWrapper>div>div {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dropDownOption .dropDownIcon {
    position: absolute;
    right: 25px;
    top: 63px;
}

.fieldWrapper sup {
    color: red;
}

.password {
    position: relative;
}

.iconToggle {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.iconToggle img {
    cursor: pointer;
    width: 18px;
}

.imageUploaderContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageUploaderContainer .innerContainer {
    position: relative;
    height: 120px;
    width: 120px;
    margin-bottom: 32px;
}

.innerContainer .camIconContainer {
    position: absolute;
    bottom: -12px;
    right: -12px;
    height: 30px;
    width: 30px;
    background-color: #51ab86;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.imageUploaderContainer .innerContainer input {
    display: none;
}

.innerContainer .userAvatar {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    border: 2px solid #d1d2d3;
}

.camIconContainer img {
    filter: brightness(0) invert(1);
}

.boxes {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 32px 0px;
    cursor: pointer;
    margin-left: 12px;
}

.boxes input[type='checkbox'] {
    all: revert;
    /* position: absolute; */
    z-index: 99;
    opacity: 0;
    left: 0px;
    /* cursor: pointer; */
}

.boxes input[type='checkbox']+label {
    display: block;
    margin-left: 18px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.boxes input[type='checkbox']+label:last-child {
    margin-bottom: 0;
}

.boxes input[type='checkbox']+label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #e59236;
    position: absolute;
    left: 0px;
    top: -1;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

.boxes input[type='checkbox']:checked+label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.Terms {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 16px;
}

.Terms .boxes {
    max-width: 256px;
    margin: 0 auto;
}

.errorText {
    margin-bottom: 0px;
    position: absolute;
    font-size: 12px;
    line-height: 1.4;
    color: #f5004a;
    /* padding-left: 14px; */
    margin-top: 5px;
}

.btnWrapper {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    margin-top: 25px;
}

.btnWrapper button {
    width: 100%;
    max-width: 375px;
    background: #51ab86;
    border-radius: 8px;
    height: 48px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    cursor: pointer;
}

.fieldWrapper>div>input {
    width: 100% !important;
}

.fieldWrapper input[type='date'] {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fieldWrapper.phoneNum>div>input {
    width: calc(100% - 30px) !important;
}

.modalBodyContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 5px 10px 32px 0px #277FE512;
    width: 688px;
}

.modalBodyContainer .modalHeader {
    padding: 24px 24px 16px;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 44.8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.modalHeader .closeIcon {
    filter: brightness(1) invert(1);
    cursor: pointer;
}

.modalBodyContainer .modalBodyInnerContainer {
    /* padding: 0 24px; */
    width: calc(100% - 48px);
    margin: 0 auto;
    max-height: 480px;
    height: 100%;
    position: relative;
}

.modalBodyContainer .camLayer {
    position: absolute;
    top: 0px;
    bottom: 0;
    height: 481px;
    width: 640px;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0.7;
}

.camLayer img {
    object-fit: cover;
    height: 481px;
}

.modalBodyContainer .webCamContainer {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage img {
    height: 100%;
    width: 100%;
}

.modalBodyContainer .modalFooter {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.modalBodyContainer .modalFooter .captureBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .submitBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .retakeBtn {
    background-color: #fff;
    border: 1px solid #51ab86;
    color: #51ab86;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

@media screen and (max-width: 1366px) {
    .header::after {
        width: 106%;
    }
}

@media screen and (max-width: 1024px) {
    .header::after {
        width: 119%;
    }

    .guestForm .fieldWrapper.sm {
        width: 50%;
        padding: 0px 12px;
    }

    .guestForm .fieldWrapper.lg {
        width: 50%;
        padding: 0px 12px;
    }

    .guestForm .fieldWrapper.md {
        width: 50%;
        padding: 0px 12px;
    }
}

@media screen and (max-width: 767px) {
    .header::after {
        width: 100%;
    }

    .wrapper {
        background: transparent;
    }

    .modalBodyContainer {
        width: calc(100vw - 64px);
    }
}

@media screen and (max-width: 450px) {
    .guestForm .fieldWrapper.sm {
        width: 100%;
        padding: 0px 12px;
    }

    .guestForm .fieldWrapper.lg {
        width: 100%;
        padding: 0px 12px;
    }

    .guestForm .fieldWrapper.md {
        width: 100%;
        padding: 0px 12px;
    }
}

@media screen and (max-width: 450px) {

    .guestForm .fieldWrapper.sm,
    .guestForm .fieldWrapper.lg,
    .guestForm .fieldWrapper.md {
        width: 100%;
    }

    .fieldWrapper>div>input {
        width: 90% !important;
    }

    .guestForm .fieldWrapper label {
        margin-bottom: 6px;
    }

    .guestForm .fieldWrapper>div>input {
        width: 100% !important;
    }

    .guestForm .fieldWrapper.phoneNum>div>input {
        width: calc(100% - 30px) !important;
    }

    .dropDownOption .dropDownIcon {
        top: 55px;
    }
}


@media screen and (max-width:767px) {
    .modalBodyContainer {
        width: calc(100vw - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer {
        height: auto;
        max-height: none;
        max-width: calc(100% - 32px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer {
        width: 100%;
        left: 0;
        right: 0;
        height: calc(100% + 1px);
    }

    .modalBodyContainer .modalBodyInnerContainer .camLayer img {
        height: 100%;
    }

    .modalBodyContainer .modalBodyInnerContainer .capturedImage,
    .modalBodyContainer .modalBodyInnerContainer .webCamContainer {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width:575px) {
    .modalBodyContainer .modalHeader h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }

    .modalBodyContainer .modalHeader h3.modalH3 {
        font-size: 22px;
    }

    .modalBodyContainer .modalFooter {
        flex-direction: column-reverse;
    }

    .modalBodyContainer .modalFooter .retakeBtn,
    .modalBodyContainer .modalFooter .submitBtn {
        width: 100%;
    }
}