/* Container for the entire skeleton */
.swiperSkeletonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px;
}

/* Swiper skeleton container */
.swiperSkeleton {
  display: flex;
  gap: 16px;
  overflow: hidden;
  width: 100%;
}

/* Individual slide skeleton */
.skeletonSlide {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  background: #d1d1d1;
}

.swiperHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
}

.swiperHeader .leftContainer {
  display: flex;
  width: 75%;
  gap: 8px;
}

.leftContainer .skeletonIcon,
.skeletoncloser {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}



/* Text container for title, description, and URL */
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  align-items: start;
}

/* Title skeleton */
.skeletonTitle {
  width: 80%;
  height: 24px;
  border-radius: 8px;
  background: #d1d1d1;

  /* Darker base color */
}

.skeletonDescriptionGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

/* Description skeleton */
.skeletonDescription {
  width: 100%;
  height: 14px;
  border-radius: 8px;
  background: #d1d1d1;
}

.skeletonDescription:last-child {
  width: 70%;
}

/* URL skeleton */
.skeletonUrl {
  width: 40%;
  height: 14px;
  border-radius: 8px;
  background: #d1d1d1;
  /* Darker base color */
}

/* Shimmer effect */
.shimmer {
  position: relative;
  overflow: hidden;
  background: #d1d1d1;
  /* Darker base color for the skeleton */
}

.shimmer::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.15), transparent);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}