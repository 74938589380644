.wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    /* border-radius: 16px; */
    flex-direction: column;
    border-radius: 16px;
}

.noFullpageLoader{
    height: 100%;
}

.fullpageLoader{
    height: 100vh;
}

.wrapper .loaderImg {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.title {
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 20px;
}
