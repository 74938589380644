.container {
    width: 100%;
    max-width: 1470px;
    padding: 30px 15px 50px;
    margin-left: auto;
    margin-right: auto;
}

.breadcrumb {
    font-size: 14px;
    line-height: 1.2;
    text-transform: capitalize;
    color: #191c21;
    font-weight: 500;
}

.breadcrumb span {
    cursor: pointer;
}

.sliderWrapper {
    width: 55%;
    position: relative;
}

.sliderWrapper .price {
    position: absolute;
    z-index: 9;
    background: #e59236;
    border-radius: 30px;
    padding: 0px 9px;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    display: inline-flex;
    right: 10px;
    top: 10px;
    align-items: center;
    justify-content: center;
}

/* p.carousel-status {
    position: unset !important;
} */
.unitDetails {
    width: 45%;
}

.detailsWrapper {
    margin-top: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.unitDetails .details {
    background: #ffffff;
    border-radius: 16px;
    margin-left: 32px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.unitDetails .unitTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    color: #191c21;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* .unitDetails .unitTitle h2 {
    margin-left: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
} */
.facilities {
    margin-top: 24px;
}

.facility {
    font-size: 18px;
    line-height: 26px;
    margin-right: 24px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.facility img {
    display: inline-block;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    object-fit: contain;
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
}

.locationInfo {
    margin-top: 34px;
}

.locationInfo .locationInfoTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d2d3;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
}

.locationInfo .subTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 16px;
}

.locationInfo .locationAddress {
    font-size: 14px;
    line-height: 1.4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}

.locationAddress img {
    display: inline-block;
    margin-right: 8px;
}

.locationDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
}

.btnWrapper {
    margin-top: 32px;
    /* text-align: center; */
}


.uploadPhotoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
}

.uploadPhotoContainer p {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    color: #707b7c;
}

.unitCard .priceNote {
    display: inline-block;
    margin-top: 16px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    display: flex;
    font-style: italic;
    color: #707b7c;
}

.uploadPhotoContainer button.btnGreen,
.btnWrapper button {
    background: #d1d2d3;
    border-radius: 8px;
    height: 60px;
    padding: 16px 24px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #8c8d90;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid #d1d2d3;
    transition: all ease 0.3s;
}


.uploadPhotoContainer button.btnGreen {
    background-color: #51ab86;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 12px;
}

.uploadPhotoContainer button.btnGreen img {
    filter: brightness(0) invert(1);
}

.btnWrapper button.btnSuccess {
    background: #51ab86;
    color: #fff;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    border: 1px solid #51ab86;
}



.btnWrapper button.btnSuccess:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #d1d2d3;
    border: 1px solid #d1d2d3;
    color: #707b7c;
}

.btnWrapper button.btnSuccess:not(:disabled):hover {
    color: #51ab86;
    background-color: #fff;
    -webkit-box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.13);
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.13);
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.modalBodyContainer {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 5px 10px 32px 0px #277FE512;
    width: 688px;
}

.modalBodyContainer .modalHeader {
    padding: 24px 24px 16px;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 44.8px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.modalHeader .closeIcon {
    filter: brightness(1) invert(1);
    cursor: pointer;
}

.modalBodyContainer .modalBodyInnerContainer {
    /* padding: 0 24px; */
    width: calc(100% - 48px);
    margin: 0 auto;
    max-height: 480px;
    height: 100%;
    position: relative;
}

.modalBodyContainer .camLayer {
    position: absolute;
    top: 0px;
    bottom: 0;
    height: 481px;
    width: 640px;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0.7;
}

.camLayer img {
    object-fit: cover;
    height: 481px;
}

.modalBodyContainer .webCamContainer {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage {
    width: 640px;
    max-width: 100%;
    height: 480px;
    max-height: 100%;
}

.modalBodyContainer .capturedImage img {
    height: 100%;
    width: 100%;
}

.modalBodyContainer .modalFooter {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.modalBodyContainer .modalFooter .captureBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .submitBtn {
    background-color: #51ab86;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.modalBodyContainer .modalFooter .retakeBtn {
    background-color: #fff;
    border: 1px solid #51ab86;
    color: #51ab86;
    padding: 12px;
    border-radius: 8px;
    width: calc(50% - 8px);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
}

.updatePaymentDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
}

.updatePaymentDetail p {
    font-size: 16px;
    line-height: 19.2px;
    color: #000000;
    text-align: center;
    max-width: 600px;
}

.updatePaymentDetail .btnSuccess {
    background-color: #51ab86;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 12px;
    border-radius: 8px;
    width: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    max-width: 320x;
    margin: 0 auto;
    border-radius: 8px;
    height: 60px;
    padding: 16px 24px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    text-transform: capitalize;
    cursor: pointer;
    transition: all ease 0.3s;
}


/* info icon wrapper */
.description {
    margin: 8px 0;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.infoIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #191919;
}

.description p {
    color: #191c21;
    font-size: 16px;
    line-height: 19.2px;
}

/* Responsive Start */
@media screen and (max-width: 1366px) {
    .container {
        max-width: 90%;
    }

    .unitDetails .details {
        padding: 24px;
    }

    .unitDetails .unitTitle {
        font-size: 24px;
        line-height: 1.2;
    }

    .facilities {
        margin-top: 16px;
    }

    .facility {
        font-size: 14px;
        line-height: 1.4;
    }

    .facility img {
        width: 16px;
    }

    .locationInfo {
        font-size: 16px;
        line-height: 1.4;
        margin-top: 16px;
    }

    .locationInfo .subTitle {
        font-size: 16px;
        line-height: 1.4;
    }

    .locationInfo .locationAddress {
        font-size: 12px;
        margin-bottom: 16px;
    }

    .locationDesc {
        font-size: 12px;
        line-height: 1.4;
    }

    .btnWrapper {
        margin-top: 32px;
        padding-bottom: 10px;
    }

    .uploadPhotoContainer button.btnGreen,
    .btnWrapper button.btnSuccess,
    .updatePaymentDetail .btnSuccess {
        font-size: 16px;
        line-height: 1.2;
        padding: 8px 16px;
        height: 44px;
    }
}

@media screen and (max-width: 1024px) {

    .sliderWrapper,
    .unitDetails {
        width: 100%;
    }

    .unitDetails .details {
        margin-left: 0px;
    }

    .unitDetails {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    .locationInfo .locationInfoTitle {
        font-size: 16px;
        line-height: 1.4;
    }

    .locationInfo .subTitle {
        font-size: 14px;
        line-height: 1.4;
    }

    .container {
        padding: 30px 15px 15px;
    }
}

@media screen and (max-width: 415px) {
    .container {
        max-width: 100%;
        padding-bottom: 15px;
    }

    .unitDetails {
        margin-bottom: 0px;
    }

    .unitDetails .details {
        padding: 0px;
    }

    .facility {
        font-size: 14px;
        line-height: 1.4;
        margin: 4px 0px;
        width: 50%;
        justify-content: center;
    }

    .facility img {
        width: 14px;
        margin-right: 4px;
    }

    .unitDetails .unitTitle {
        font-size: 20px;
        line-height: 1.2;
    }

    .btnWrapper {
        margin-top: 20px;
        padding-bottom: 15px;
    }
}