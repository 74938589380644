.container {
    width: 1362px;
    position: relative;
}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #d1d2d3;
    position: relative;
}

.facilities {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
}

.facilities .facility {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 32px;
}

.facilities .facility img {
    -webkit-filter: brightness(0%);
    filter: brightness(0%);
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
}

.headerContent .headerTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
}

.headerContent .headerTitle span {
    margin-left: 5px;
}

.closeBtn {
    cursor: pointer;
}

.header .wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.header .back {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
}

.header .back img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header .flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% - 30px);
}

/* Responsive Start */
@media screen and (max-width: 1400px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    .container {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 135px);
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 130px);
    }

    .headerContent {
        width: 100%;
    }

    .headerContent .withConfirm {
        width: calc(100% - 52px);
    }

    .facilities {
        flex-wrap: wrap;
        gap: 8px 4px;
    }

    .facilities .facility {
        margin-right: 16px;
    }

    .header .back {
        margin-right: 12px;
        width: 40px;
        height: 40px;
        min-width: 40px;
    }

    .wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .facilities .facility {
        margin-right: 8px;
        font-size: 12px;
    }

    .facilities .facility img {
        margin-right: 4px;
        width: 14px;
        height: 14px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .closeBtn img {
        width: 25px;
    }

    .closeBtn {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}