/* AmenitySwiper.module.css */
.amenitySwiper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  padding: 20px;
  overflow-x: hidden;
  height: 100vh;
}

.amenityHeader {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.amenityHeader .leftContainer {
  display: flex;
  width: 84%;
  gap: 8px;
  align-items: center;
}

.amenityHeader .iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amenityHeader .iconWrapper img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.title {
  font-size: 19px;
  line-height: 20px;
  text-align: left;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.location {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.slider {
  width: 100%;
  height: 200px;
  max-height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.description {
  font-size: 16px;
  line-height: 19.2px;
  color: #191c21;
  text-align: left;
  max-height: 60px;
  height: 100%;
  overflow-y: auto;
  word-break: break-all;
}

.description.fullHeight {
  max-height: calc(100vh - 80px);
}

.description.withURL {
  max-height: calc(100vh - 100px);
}

.description::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #F5F5F5;
}

.description::-webkit-scrollbar {
  width: 4px;
  background-color: #F5F5F5;
}

.description::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #51AB86;
}


.link {
  font-size: 16px;
  line-height: 19.2px;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
}

.link:hover {
  text-decoration: underline;
}

.mediaImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.mediaVideo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}