input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .back {
    min-height: 48px;
    min-width: 48px;
    border: 1px solid #d1d2d3;
    border-radius: 8px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
}

.header .back img {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.header .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #191c21;
}

.detailsBody {
    margin-top: 16px;
}

.detailsBody form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.detailsBody .fieldWrapper {
    width: 50%;
    max-width: 48%;
    display: inline-block;
}

.detailsBody .fieldWrapper label {
    display: block;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
    color: #191c21;
}

.detailsBody .fieldWrapper {
    margin-bottom: 24px;
}

.detailsBody .fieldWrapper:last-child {
    margin-bottom: 0px;
}

.detailsBody .fieldWrapper input {
    border: 1px solid #191c21;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    padding: 10px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
}

.btnWrapper {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnWrapper button {
    width: 100%;
    max-width: 375px;
    background: #51ab86;
    border-radius: 8px;
    height: 48px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    cursor: pointer;
}

.btnWrapper button:disabled {
    cursor: not-allowed;
    background-color: #a9a9a9;
    color: #191c21;
}

.btnWrapper span {
    color: #51ab86;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    margin-top: 18px;
}

.detailsBody form {
    justify-content: center;
    position: relative;
}

.detailsBody .errorText {
    /* margin-bottom: 0px; */
    /* position: absolute; */
    font-size: 14px;
    line-height: 1.4;
    color: #f5004a;
    top: 50px;
    left: 1px;
    width: 100%;
}

.detailsWrapper {
    padding-top: 20px;
    border-top: 1px solid #a9a9a9;
    max-width: 395px;
    margin: auto;
}

.detailsWrapper.noCreditCard {
    border-top: 1px solid transparent;
    padding-top: 0;
}

.detailsWrapper.noCreditCard .detailsBody {
    margin-top: 0;
}

.detailsWrapper .header {
    justify-content: center;
}

.detailsBody .chargeInfo {
    width: 100%;
    padding-bottom: 20px;
}

.detailsBody .chargeInfo .description {
    font-size: 14px;
    line-height: 1.4;
    top: 50px;
    left: 1px;
    color: #191c21;
}

.detailsBody .chargeInfo .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #191c21;
    padding-bottom: 10px;
}

.boxes {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 240px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 40px;
}

.boxes.customBoxes {
    margin-top: 0;
}

.boxes input[type='checkbox'] {
    all: revert;
    position: absolute;
    z-index: 99;
    opacity: 0;
    left: 0px;
}

.boxes input[type='checkbox']+label {
    display: block;
    margin-left: 32px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.2;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.boxes input[type='checkbox']+label:last-child {
    margin-bottom: 0;
}

.boxes input[type='checkbox']+label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #e59236;
    position: absolute;
    left: 0px;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.12s, border-color 0.08s;
    -o-transition: all 0.12s, border-color 0.08s;
    transition: all 0.12s, border-color 0.08s;
}

.boxes input[type='checkbox']:checked+label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.CCModal {
    max-width: 460px;
    width: 100%;
    padding: 24px;
}

.CCModal .modalHeader {
    padding-bottom: 16px;
    border-bottom: 1px solid #d1d2d3;
}

.CCModal .modalHeader h3 {
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    font-weight: 600;
}

.CCModal .modalBody {
    padding: 16px 0 0 0;
}

.modalBody p {
    font-size: 16px;
    line-height: 24px;
    color: #191c21;
}

.CCModal .modalFooter {
    padding-top: 24px;
}

.modalFooter button {
    width: 100%;
    max-width: 100%;
    background: #51ab86;
    border-radius: 8px;
    height: 48px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    cursor: pointer;
}