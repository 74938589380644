.outerContainer {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.outerbg {
    /* background: url(../../images/outer-bg.png) no-repeat bottom center; */
    position: relative;
    background-size: cover;
    background-color: #f4f7fb;
}
.outerbg:before,
.outerbg:after {
    content: '';
    position: absolute;
    background-size: contain;
    display: none;
}
.outerbg:before {
    background: url(../../images/visit-apartment-boy.svg) no-repeat bottom center;
    width: 371px;
    height: 496px;
    bottom: 20px;
    left: 78px;
}
.outerbg:after {
    background: url(../../images/request-token-girl.svg) no-repeat bottom center;
    width: 371px;
    height: 496px;
    bottom: 50px;
    right: 45px;
}

/* Responsive Start */
@media screen and (max-width: 1366px) {
    .outerbg:before,
    .outerbg:after {
        width: 250px;
        height: 100%;
        background-size: contain;
    }
}

@media screen and (max-width: 1024px) {
    .outerContainer {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        background-size: contain;
    }
    .outerbg:before,
    .outerbg:after {
        width: 150px;
    }
}
@media screen and (max-width: 767px) {
    .outerbg:before,
    .outerbg:after {
        display: none;
    }
}
