.fluid {
    position: fixed;
    width: 100%;
    z-index: 999;
    background: #ffffff;
    box-shadow: 5px 10px 32px rgb(39 127 229 / 7%);
}

.outerContainer {
    max-width: 1410px;
    padding: 16px 15px;
    margin: auto;
}

.flexContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.logo img {
    cursor: pointer;
    max-width: 165px;
}

.callIcon {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #51AB86;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}

@media screen and (max-width: 767px) {
    .logo img {
        max-width: 105.37px;
    }

    .callIcon {
        font-size: 14px;
        line-height: 1.2;
        height: 30px;
        padding: 8px 18px;
    }

    .callIcon img {
        width: 12px;
        height: 12px;
    }
}