.fluid {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 5px 10px 32px rgb(39 127 229 / 7%);
}

.outerContainer {
    max-width: 1470px;
    padding: 16px 15px;
    margin: auto;
}

.flexContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.logo img {
    cursor: pointer;
    max-width: 165px;
}

.headerMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 16px;
    height: 40px;
}

.headerMenu ul li {
    display: inline-block;
    margin: 0px 28px;
}

.headerMenu ul li a {
    color: #ffb800;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
    cursor: pointer;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.userDropdownContainer {
    position: relative;
}

.userDropdownContainer .userProfile {
    position: relative;
    background-color: #ffb800;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
}

.userDropdownContainer .userProfile .userProfileImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.userDropdownContainer .userProfile.withProfile {
    background-color: #d1d2d3;
}

.userDropdownContainer .userProfile span {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}

.userDropdownContainer .dropdown {
    position: absolute;
    top: 56px;
    width: 220px;
    height: 100%;
    transition: 0.3s all ease-in-out;
    display: none;
    background-color: #fff;
    padding: 8px 4px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    right: 0;
    z-index: 9;
}

.userDropdownContainer .dropdown.show {
    height: auto;
    display: flex;
}

.userDropdownContainer .dropdown .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.userDropdownContainer .dropdown .list .item {
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
}

.list .item span {
    font-size: 16px;
    line-height: 19px;
}

.userDropdownContainer .dropdown .list .item.error {
    color: #f5004a;
    display: flex;
    align-items: center;
    gap: 4px;
}

.userDropdownContainer .dropdown .list .item:hover {
    background-color: #f5f9fe;
    border-radius: 4px;
}

.backDrop {
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.callIcon {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #51AB86;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
}


/* .headerMenu ul li a:hover {
    color: #f8dc7f;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
} */
.hemburger {
    margin-left: 15px;
    display: none;
}

.hemburger img {
    width: 30px;
    /* -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1); */
    cursor: pointer;
}

.icoMargin {
    margin-right: 10px;
}

.btnWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
}

/* Responsive Start */
@media screen and (max-width: 1366px) {
    /* .outerContainer {
        max-width: 90%;
    } */
}

@media screen and (max-width: 1024px) {
    /* .outerContainer {
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    } */

    .logo img {
        width: 135px;
    }

    .headerMenu ul li a {
        font-size: 14px;
        line-height: 1.4;
    }

    .headerMenu ul li {
        margin: 0px 12px;
    }

    .headerMenu ul li:last-child {
        margin-right: 24px;
    }

    .headerMenu ul {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    /* .outerContainer {
        padding-left: 30px;
        padding-right: 30px;
    } */

    /* .btnWrapper {
        display: none;
    } */
    .hemburger {
        display: inline-block;
    }
}

@media screen and (max-width:767px) {
    .headerMenu {
        gap: 10px;
    }

    .headerMenu .callIcon,
    .headerMenu .btnWrapper button {
        font-size: 12px;
        line-height: 1.2;
        height: 36px;
        padding: 8px 10px;
        gap: 5px;
    }

    .headerMenu .helpBtn,
    .headerMenu .btnWrapper button {
        font-size: 0px;
        min-width: 40px;
        height: 40px;
        padding: 2px 1px;
        gap: 0px;
        border-radius: 50%;
    }

    .headerMenu .userDropdownContainer .dropdown {
        top: 48px;
    }

    .callIcon img,
    .icoMargin {
        width: 16px;
        height: 16px;
        margin: 0;
    }
}

@media screen and (max-width: 359px) {

    /* .outerContainer {
        padding-left: 15px;
        padding-right: 15px;
    } */
    .headerMenu {
        gap: 5px;
    }

    .headerMenu .callIcon {
        height: 32px;
    }

    .headerMenu .helpBtn,
    .headerMenu .btnWrapper button,
    .headerMenu .userProfile,
    .headerMenu .userProfile .userProfileImage {
        min-width: 32px;
        height: 32px;
        width: 32px;
    }

    .headerMenu .userDropdownContainer .dropdown {
        top: 40px;
    }
}