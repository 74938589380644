.comingSoonContainer {
  position: relative;
  height: calc(100vh - 122px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
}

.pineapple {
  font-size: 280px;
  height: 1em;
  width: .9em;
  position: relative;
}

.pineapple div {
  position: absolute;
}

.leaf {
  background-color: darkgreen;
  border-radius: 90% 0 90% 0;
  height: 20%;
  top: 10%;
  width: 20%;

}

.leaf.left {
  animation: leafLeft 0.25s ease-in-out alternate infinite;
  left: 50%;
  transform-origin: bottom left
}

.leaf.middle {
  animation: leafMiddle 0.25s ease-in-out alternate infinite;
  background-color: green;
  left: 40%;
  top: 5%;
  transform: rotate(-40deg);
}

.leaf.right {
  animation: leafRight 0.25s ease-in-out alternate infinite;
  left: 50%;
  transform-origin: bottom left
}

.pineapple .body {
  animation: body 0.25s ease-in-out alternate infinite;
  background-color: #FFDE3E;
  border-radius: 40%;
  height: 50%;
  left: 24%;
  top: 27%;
  width: 54%;
}

.pineapple .body::after {
  background: repeating-linear-gradient(-45deg, transparent, transparent 15%, #F2A416 15%, #F2A416 18%), repeating-linear-gradient(45deg, transparent, transparent 15%, #F2A416 15%, #F2A416 18%);
  border-radius: 40%;
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
  opacity: 0.5;
}

.eye {
  animation: eye 1.04s infinite;
  background-color: black;
  border-radius: 100%;
  height: 15%;
  top: 35%;
  width: 15%;
  z-index: 2;

}

.eye.left {
  left: 25%;
}

.eye.right {
  right: 25%;
}

.mouth {
  background-color: black;
  border-radius: 20% 20% 80% 80%;
  height: 12%;
  left: 41%;
  top: 50%;
  width: 18%;
  z-index: 2;
}

.arm {
  background-color: #FFDE3E;
  border-radius: 100vw;
  height: 50%;
  top: 20%;
  width: 20%;
}

.arm.left {
  animation: armLeft 0.26s ease-in-out alternate infinite;
  left: 10%;
  transform-origin: bottom left;
}

.arm.right {
  animation: armRight 0.26s ease-in-out alternate infinite;
  right: 10%;
  transform-origin: bottom right;
}

.leg {
  animation: foot 0.26s alternate 0.13s infinite;
  background: #FFDE3E;
  border-radius: 100vw;
  bottom: -20%;
  height: 40%;
  width: 20%;

}

.leg.left {
  left: 20%;
}

.leg.right {
  right: 20%;
}

.shadow {
  animation: shadow 0.26s ease alternate infinite;
  background-color: tan;
  border-radius: 100%;
  bottom: 5%;
  height: 10%;
  left: 28%;
  opacity: 0.5;
  width: 46%;
}

.comingSoonContainer p {
  /* font-size: 2vw;
  line-height: 1.2;
  margin-top: 16px;
  margin-bottom: 30px; */
  font-size: 40px;
  line-height: 1;
  color: transparent;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-text-stroke: 2px #e59236;
  margin-bottom: 20px;
}

.comingSoonContainer .btn {
  background-color: #e59236;
  border-radius: 8px;
  padding: 6px 21px;
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

@keyframes leafLeft {
  from {
    transform: rotate(-75deg)
  }

  to {
    transform: rotate(-85deg)
  }
}

@keyframes leafMiddle {
  from {
    transform: translateY(-10%) rotate(-40deg)
  }

  to {
    transform: translateY(10%) rotate(-40deg)
  }
}

@keyframes leafRight {
  from {
    transform: rotate(-5deg)
  }

  to {
    transform: rotate(5deg)
  }
}

@keyframes armLeft {
  from {
    transform: rotate(-40deg)
  }

  to {
    transform: rotate(-50deg)
  }
}

@keyframes armRight {
  from {
    transform: rotate(40deg)
  }

  to {
    transform: rotate(50deg)
  }
}

@keyframes body {
  from {
    transform: translateY(-2%)
  }

  to {
    transform: translateY(2%)
  }
}

@keyframes eye {
  100% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}

@keyframes foot {
  from {
    transform: translateY(-10%)
  }

  to {
    transform: translateY(10%)
  }
}

@keyframes shadow {
  from {
    transform: scaleX(1.1)
  }

  to {
    transform: scaleX(1)
  }
}