.tourCompletionStepper {
  height: 100%;
  overflow-y: auto;
}

.tourCompletionStepper .innerContainer {
  padding: 24px;
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  padding-bottom: 40px;
}

.tourCompletionStepper .innerContainer .imageContainer {
  width: 684px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 24px;
  overflow: hidden;
}

.tourCompletionStepper .innerContainer .imageContainer img {
  height: auto;
}

.tourCompletionStepper .innerContainer .lowerContainer {
  margin-top: 42px;
}

.tourCompletionStepper .innerContainer .lowerContainer .pineappleLogo {
  width: 237px;
  height: 72px;
}

.tourCompletionStepper .innerContainer .lowerContainer .pineappleLogo img {
  height: auto;
}

.tourCompletionStepper .innerContainer .lowerContainer .formStepper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.formStepper .formControl p {
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: left;
  color: #191C21;
  width: auto;
  display: ruby-text;
}

.formStepper .formControl p.accessCode {
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  color: #191C21;
  margin-top: 16px;
}

.formStepper .btnWrapper {
  display: flex;
  align-items: center;
  margin-top: 48px;
  gap: 24px;
}

.formStepper .uploadImageButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 42px 16px;
  width: 100%;
  border-radius: 16px;
  border: 1px dashed #3674EE;
  background-color: rgba(39, 127, 229, 0.07);
  cursor: pointer;
  margin-top: 28px;
}

.formStepper .uploadImageButton p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #000000;
}

.formStepper .uploadBtn {
  width: 100%;
  margin-top: 66px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  background-color: #51AB86;
  border: 1px solid #51AB86;
  padding: 18px 16px;
  cursor: pointer;
}

.formStepper .uploadBtn:disabled {
  background-color: #D1D2D3;
  border-color: #d1d2d3;
  color: #8C8D90;
}

.formStepper .btnWrapper button,
.formStepper .btnWrapper a {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  max-width: 178px;
  width: 100%;
  height: 86px;
  padding: 29px 16px;
  border: 1px solid #D1D2D3;
  background-color: #fff;
  border-radius: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #191C21;
  transition: all 0.3s ease-in-out;
}

.formStepper .btnWrapper a {
  max-width: max-content;
}

.formStepper .btnWrapper a:hover,
.formStepper .btnWrapper button:hover,
.formStepper .btnWrapper button.active {
  border-color: #3674EE;
  color: #3674EE;
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 5px 10px 32px 0px #277FE512;
  width: 688px;
}

.modalBodyContainer .modalHeader {
  padding: 24px 24px 16px;
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.modalBodyContainer .modalHeader h3 {
  font-family: Rubik;
  font-size: 32px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: left;
}


.modalHeader .closeIcon {
  filter: brightness(1) invert(1);
  cursor: pointer;
}

.modalBodyContainer .modalBodyInnerContainer {
  /* padding: 0 24px; */
  width: calc(100% - 48px);
  margin: 0 auto;
  max-height: 480px;
  height: 100%;
  position: relative;
}

.modalBodyContainer .camLayer {
  position: absolute;
  top: 0px;
  bottom: 0;
  height: 481px;
  width: 640px;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0.7;
}

.camLayer img {
  object-fit: cover;
  height: 481px;
}

.modalBodyContainer .webCamContainer {
  width: 640px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
}

.modalBodyContainer .capturedImage {
  width: 640px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
}

.modalBodyContainer .capturedImage img {
  height: 100%;
  width: 100%;
}

.modalBodyContainer .modalFooter {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.modalBodyContainer .modalFooter .captureBtn {
  background-color: #51ab86;
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.modalBodyContainer .modalFooter .submitBtn {
  background-color: #51ab86;
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  width: calc(50% - 8px);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

.modalBodyContainer .modalFooter .retakeBtn {
  background-color: #fff;
  border: 1px solid #51ab86;
  color: #51ab86;
  padding: 12px;
  border-radius: 8px;
  width: calc(50% - 8px);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width:767px) {

  .tourCompletionStepper .innerContainer .lowerContainer {
    margin-top: 24px;
  }

  .tourCompletionStepper .innerContainer .lowerContainer .pineappleLogo {
    width: 180px;
    height: auto;
  }

  .tourCompletionStepper .innerContainer .lowerContainer .formStepper {
    margin-top: 16px;
  }

  .formStepper .formControl p {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
  }

  .formStepper .btnWrapper {
    gap: 16px;
    margin-top: 24px;
  }

  .formStepper .btnWrapper a,
  .formStepper .btnWrapper button {
    padding: 16px;
    height: auto;
  }

  .modalBodyContainer {
    width: calc(100vw - 32px);
  }

  .modalBodyContainer .modalBodyInnerContainer {
    height: auto;
    max-height: none;
    max-width: calc(100% - 32px);
  }

  .modalBodyContainer .modalBodyInnerContainer .camLayer {
    width: 100%;
    left: 0;
    right: 0;
    height: calc(100% + 1px);
  }

  .modalBodyContainer .modalBodyInnerContainer .camLayer img {
    height: 100%;
  }

  .modalBodyContainer .modalBodyInnerContainer .capturedImage,
  .modalBodyContainer .modalBodyInnerContainer .webCamContainer {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width:520px) {
  .modalBodyContainer .modalHeader h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  .modalBodyContainer .modalFooter {
    flex-direction: column-reverse;
  }

  .modalBodyContainer .modalFooter .retakeBtn,
  .modalBodyContainer .modalFooter .submitBtn {
    width: 100%;
    padding: 16px 8px;
    font-size: 16px;
  }
}