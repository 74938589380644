.item {
  padding: 8px 12px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #f5004a;
}

.item:hover {
  background-color: #f5f9fe;
  border-radius: 4px;
}

@media screen and (max-width:767px) {
  button.item {
    padding: 8px 12px !important;
    cursor: pointer;
    width: 100%;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #f5004a !important;
  }

  button.item:hover {
    background-color: #f5f9fe !important;
    border-radius: 4px !important;
  }
}