.map-container {
    height: calc(100vh - 1px);
    height: -webkit-fill-available;
    position: relative;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

.map-view-container {
    width: 100vw;
    height: calc(100vh - 121px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btnGreen {
    background-color: #51ab86;
    border-radius: 2px;
    padding: 8px 16px;
    height: 36px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    -webkit-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    transition: all ease 0.3s;
    text-transform: capitalize;
    cursor: pointer;
    border: 1px solid #51ab86;
    transition: all ease 0.3s;
    width: 100%;
}

.btnGreen:disabled {
    background-color: #d1d2d3;
    border: 1px solid #d1d2d3;
    color: #8c8d90;
}

.mappedin-map {
    width: 100%;
    /* height: calc(100% - 122px) !important; */
    height: 100%;
    touch-action: auto;
}

.custom-loader {
    position: fixed;
    z-index: 101;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    max-width: 732px;
    margin: 0 16px;
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 10px 32px 0px #277fe512;
}

.error-container .notFoundImage {
    width: 100%;
    max-width: 120px;
}

.error-container .mapNotFound {
    max-width: 748px;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.error-container p {
    font-size: 2vw;
    line-height: 1.2;
    margin-top: 12px;
    margin-bottom: 24px;
    width: 100%;
    padding: 0 16px;
    text-align: center;
}

.error-container .btnGreen {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #fff;
    background-color: #51ab86;
    border-radius: 8px;
    min-height: 40px;
    cursor: pointer;
}

.chatBotContainer.engrain-map {
    right: 80px;
    bottom: 72px;
}

.chatBotContainer.no-map-view {
    right: 60px;
}

.bottom-btn-container {
    position: absolute;
    bottom: 60px;
    left: 20px;
    display: flex;
    gap: 16px;
    z-index: 99;
}

.bottom-btn-container .map-chatbtn,
.bottom-btn-container .map-callbtn,
.bottom-btn-container .apply-btn {
    border-radius: 8px;
    background: #e59236;
    padding: 6px 21px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid #eaeaea;
}

.bottom-btn-container .apply-btn {
    background-color: #51ab86;
    box-shadow: 0 0 10px 0px #d3d3d3;
    border: none;
}

.bottom-btn-container .map-callbtn {
    right: auto;
    left: 20px;
    background: #e59236;
    box-shadow: 0 0 10px 0px #d3d3d3;
    border: none;
}

/* mapped in style */

.mappedin-wrapper {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.custom-location-selector-wrapper {
    position: fixed;
    left: 10px;
    top: 81.25px;
    z-index: 90;
    width: 424px;
    background-color: #fff;
    padding: 24px;
    border-radius: 0 0 16px 16px;
    height: auto;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 99;
}

.custom-location-selector-wrapper.active {
    height: 520px;
}

.custom-select-input {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #b2b3b5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 99;
    gap: 10px;
    cursor: pointer;
}

.custom-select-input.border-bottom {
    border-bottom: 1px solid #d1d2d3;
    padding-bottom: 16px;
}

.custom-select-input .search-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.custom-select-input .action-btn-wrapper {
    display: inline-flex;
    gap: 24px;
    align-items: center;
}

.custom-select-input .search-btn {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #3f4246;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.custom-select-input .search-icon-wrapper {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #191c21;
}

.selector-backdrop {
    position: fixed;
    z-index: 11;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.custom-selector-dropdown {
    width: 100%;
}

.custom-selector-dropdown {
    padding-top: 24px;
    height: calc(100% - 45px);
}

.custom-selector-dropdown .tab-wrapper {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 16px;
    background-color: #f4f7fe;
    margin-bottom: 12px;
}

.custom-selector-dropdown .tab-wrapper .tab {
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    border: none;
    background-color: transparent;
    background: none;
    color: #3f4246;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    cursor: pointer;
}

.custom-selector-dropdown .tab-wrapper .tab.active {
    color: #fff;
    background-color: #3674ee;
}

.location-listing {
    height: calc(100% - 76px);
    overflow-y: auto;
}

.custom-location-selector-wrapper.extra-bottom-space .custom-selector-dropdown {
    height: calc(100% - 204px);
}

.custom-location-selector-wrapper.small-bottom-space .custom-selector-dropdown {
    height: calc(100% - 160px);
}


.location-listing .dropdown-item {
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #3f4246;
    background-color: #fff;
    cursor: pointer;
}

.location-listing .dropdown-item:hover {
    background-color: #f0f4fc;
}

.direction-wrapper {
    width: 100%;
    background-color: #fff;
    margin-bottom: -8px;
}

.direction-wrapper .get-direction {
    padding: 6.5px;
    margin: 0 auto;
    width: auto;
    display: flex;
    gap: 8px;
    border: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
    background-color: #fff;
    color: #191C21;
}

.destination-selector-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #d1d2d3;
}

.destination-selector-wrapper label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #b2b3b5;
}

.destination-selector-wrapper .destination-tab-wrapper {
    display: flex;
    padding: 0 16px 12.5px 16px;
    justify-content: space-between;
}

.destination-tab-wrapper .tab {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    background-color: transparent;
    background: none;
    border: none;
    color: #b2b3b5;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.destination-tab-wrapper .tab.active {
    color: #191c21;
}

.custom-select-input .custom-react-dropdown-select {
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    margin: 0;
    padding: 0;
    min-height: 20px;
}

.custom-select-input .custom-react-dropdown-select:focus,
.custom-select-input .custom-react-dropdown-select:focus-visible {
    outline: none;
    box-shadow: none;
}

.select-element {
    width: 100%;
    padding: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    font-size: 16px;
}

.select-option {
    padding: 8px;
    font-size: 16px;
}

.custom-react-dropdown-select .custom-react-dropdown-input-container {
    position: relative;
    width: 100%;
    border: none;
    height: 100%;
    border: none;
    padding: 0;
}

.css-wmy1p7-ReactDropdownSelect.custom-react-dropdown-select,
.css-wmy1p7-ReactDropdownSelect.custom-react-dropdown-select:focus,
.css-wmy1p7-ReactDropdownSelect.custom-react-dropdown-select:focus-within,
.css-wmy1p7-ReactDropdownSelect.custom-react-dropdown-select:hover {
    border: none;
    outline: none;
    box-shadow: none;
}

.react-dropdown-select-dropdown-handle {
    display: none;
}

.custom-react-dropdown-select .custom-react-dropdown-input-container input {
    width: 100%;
    border: none;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
}

.custom-react-dropdown-select .custom-react-dropdown-input-container input::placeholder {
    color: #b2b3b5
}

.custom-react-dropdown-select .custom-react-dropdown-input-container .action-button-container {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 16px;
    cursor: pointer;
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.react-dropdown-select-content span {
    display: none;
}

.level-selector {
    position: fixed;
    top: 81px;
    left: 444px;
    z-index: 11;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 0 0 16px 16px;
    background-color: white;
    padding: 24px;
    width: 120px;
}

.level-selector .react-dropdown-select.custom-level-selector-dropdown {
    border: none;
    padding: 0;
    min-height: 19px;
    color: #3F4246;
    font-size: 16px;
    line-height: 19.2px;
    position: relative;
}

.level-selector span {
    font-size: 14px;
    line-height: 16.8px;
    color: #B2B3B5;
}

.react-dropdown-select.custom-level-selector-dropdown:focus,
.react-dropdown-select.custom-level-selector-dropdown:focus-within,
.react-dropdown-select.custom-level-selector-dropdown:hover {
    box-shadow: none;
    outline: none;
    border: none;
}

.react-dropdown-select.custom-level-selector-dropdown span {
    display: flex;
    font-size: 16px;
    line-height: 19.2px;
    color: #3F4246;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-content {
    flex-wrap: nowrap;
    width: 100%;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-input {
    display: none;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown-handle {
    display: none;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown {
    width: 120px;
    left: -24px;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown .react-dropdown-select-item {
    padding: 12px;
    background-color: white;
    font-size: 16px;
    line-height: 19.2px;
    color: #3F4246;
}

.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown .react-dropdown-select-item.react-dropdown-select-item-selected,
.react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown .react-dropdown-select-item:hover {
    background-color: #F0F4FC;
}


.custom-react-dropdown-select .react-dropdown-select-dropdown {
    border: none;
    max-height: 270px;
    transition: height 0.5s ease-in-out;
}


.custom-react-dropdown-select .react-dropdown-select-item {
    padding: 12px;
    font-size: 16px;
    line-height: 19.2px;
    color: #3F4246;
}

.map-action-butto-container {
    position: fixed;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 9;
}

.map-action-butto-container .map-action-btn {
    position: relative;
    background-color: white;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}

.map-info-butto-container {
    position: fixed;
    right: 24px;
    top: 11.5vh;
    z-index: 9;
}

.popup-header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.popup-header img {
    height: 40px;
    width: 40px;
}

.popup-header h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #191C21;
    text-transform: capitalize;
}

.map-info-butto-container .map-info-btn {
    position: relative;
    background-color: white;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: none;
}

.map-footer {
    background-color: #191C21;
    padding: 0 16px;
}

.map-footer .inner-container {
    min-height: 87px;
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
}

.map-footer .action-btn-wrapper {
    display: flex;
    gap: 24px;
    align-items: center;
}

.map-footer .action-btn-wrapper .rental-btn {
    padding: 10px 30px;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    outline: 1px solid #FFFFFF;
    border-radius: 6px;
    text-decoration: none;
}

.map-footer .action-btn-wrapper .call-btn {
    display: flex;
    padding: 10px 30px;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    background-color: #FFB800;
    text-decoration: none;
    border-radius: 6px;
}

.mappedin-error-wrapper {
    position: relative;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mappedin-error-wrapper .map-error-container {
    height: 100%;
    margin: 0 auto;
    box-shadow: none;
    width: 100%;
}

.mappedin-error-wrapper .map-error-container.error-container p {
    font-size: 1.5vw;
}

.marker-popup {
    max-width: 440px;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Carousel Container */
.carousel-container {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    width: 400px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Carousel Wrapper */
.carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    overflow: hidden;
    position: relative;
}

/* Individual Carousel Items */
.carousel-item {
    flex: 0 0 100%;
    display: none;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.carousel-item.active {
    display: flex;
    opacity: 1;
}

/* Images and Videos in Carousel */
.swiper img,
.swiper video {
    max-width: 100%;
    width: 100%;
    height: 240px;
    border-radius: 8px;
    object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 24px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.6);

}

/* Navigation Buttons */
.carousel-prev,
.carousel-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transition: background-color 0.3s ease;
}

.carousel-prev {
    left: 10px;
}

.carousel-next {
    right: 10px;
}

.carousel-prev:hover,
.carousel-next:hover {
    background: rgba(0, 0, 0, 0.7);
}

.carousel-prev:disabled,
.carousel-next:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Indicators */
.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
}

.carousel-indicators .indicator {
    width: 12px;
    height: 12px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
    border: none;
}

.carousel-indicators {
    z-index: 9;
}

.swiper {
    width: 100%;
}

.carousel-indicators .indicator.active {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.link a {
    font-size: 14px;
    line-height: 19.2px;
    color: #191C21;
    text-decoration: underline;
    cursor: pointer;
}

.autoplay-progress {
    display: flex;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 99;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .carousel-container {
        max-width: 100%;
    }

    .carousel-prev,
    .carousel-next {
        width: 28px;
        height: 28px;
        font-size: 16px;
    }

    .carousel-indicators .indicator {
        width: 10px;
        height: 10px;
    }
}


@media screen and (max-width: 1024px) {
    .mappedin-map {
        height: calc(100vh - 110px) !important;
    }

    .custom-location-selector-wrapper,
    .level-selector {
        top: 72px;
    }

    .map-view-container {
        height: calc(100vh - 112px);
    }
}

@media screen and (max-width: 767px) {
    .App.mappedin-app {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
    }

    .map-container {
        height: -webkit-fill-available;
    }

    .map-view-container {
        width: 100%;
        height: calc(100% - 72px);
        position: relative;
        align-items: flex-start;
    }

    .map-container .inline-filter-section {
        flex-direction: column;
        width: 100%;
        padding: 0 16px 16px;
        gap: 12px;
    }

    .map-container .inline-filter-section>div {
        width: 100%;
    }

    .map-container .inline-filter-section .custom-react-dropdown-select {
        min-width: 0;
        width: 100%;
    }

    .map-container .mappedin-map {
        height: calc(100% - 170px);
        width: 100%;
    }

    .result-text {
        position: absolute;
        top: 170px;
        margin: 0;
        z-index: 1;
        background-color: #fff;
        padding: 8px;
        width: 100%;
    }

    .mappedin-map {
        height: calc(100dvh - 130px) !important;
    }

    .chatBotContainer.engrain-map {
        right: 36px;
        bottom: 36px;
    }

    .chatBotContainer.no-map-view {
        right: 24px;
        bottom: 24px;
    }

    .bottom-btn-container {
        bottom: 24px;
        left: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .bottom-btn-container.mapped-in {
        bottom: 72px;
    }

    .custom-selector-dropdown .tab-wrapper {
        padding: 0;
    }

    .error-container {
        margin: 32px 0 0 0;
        padding: 16px;
        box-shadow: none;
    }

    .mappedin-error-wrapper .map-error-container.error-container p {
        font-size: 4.25vw;
    }

    .map-info-butto-container {
        top: 92px;
        right: 8px;
        transform: translateY(0);
    }

    .map-footer .inner-container {
        min-height: 57px;
        padding: 13px 8px;
    }

    .map-footer .action-btn-wrapper {
        gap: 12px;
    }

    .map-footer .action-btn-wrapper .rental-btn {
        padding: 2px 8px;
        font-size: 12px;
    }

    .map-footer .action-btn-wrapper .call-btn {
        padding: 2px 8px;
        font-size: 12px;
    }

    .custom-location-selector-wrapper {
        width: calc(100vw - 96px);
        max-height: calc(100dvh - 156px);
        left: 16px;
        border-radius: 16px;
        padding: 12px;
        bottom: 75px;
        top: auto;
    }

    .level-selector {
        width: 92px;
        right: 16px;
        border-radius: 16px;
        top: 88px;
        left: 16px;
        padding: 12px;
    }

    .react-dropdown-select.custom-level-selector-dropdown .react-dropdown-select-dropdown {
        left: -14px;
        margin-top: 2px;
    }

    .custom-selector-dropdown .tab-wrapper .tab {
        font-size: 12px;
        padding: 8px 6px;
    }

    .location-listing {
        max-height: calc(100% - 96px);
    }

    .map-action-butto-container .map-action-btn {
        width: 28px;
        height: 28px;
    }

    .map-action-butto-container .map-action-btn:first-child svg {
        width: 15px;
        height: 10px;
    }

    .map-action-butto-container .map-action-btn:nth-child(2) svg {
        width: 13px;
        height: 2px;
    }

    .map-action-butto-container .map-action-btn:nth-child(3) svg {
        width: 10px;
        height: 10px;
    }

    .mappedin-error-wrapper .map-error-container {
        max-height: calc(100% - 58px);
    }

    .custom-select-input .search-input-wrapper label {
        display: none;
    }

    .custom-select-input .search-icon-wrapper {
        width: 36px;
        height: 36px;
    }

    .destination-selector-wrapper .destination-tab-wrapper {
        padding: 0 8px 8px;
    }

    .destination-tab-wrapper .tab {
        font-size: 12px;
        line-height: 16px;
        padding: 0;
    }

    .marker-popup {
        max-width: 360px;
    }

    .swiper img,
    .swiper video {
        height: 100%;
        aspect-ratio: 16/9;
    }
}

@media screen and (max-width:420px) {
    .marker-popup {
        max-width: calc(100vw - 80px);
        width: 100%;
    }

    .swiper img,
    .swiper video {
        height: 100%;
        aspect-ratio: 16/9;
    }
}